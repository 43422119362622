//Followup

import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { showComponent } from "../../helper/helpers";
import axios from "axios";

const InvoiceFollowUp = () => {
  const params = useParams();
  const [followUpData, setfollowUpData] = useState([]);
  const [srfObject, createSrfObject] = useState({
    enquiryId: params.enquiryId,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateEnquiryObject = (key, value) => {
    let newEnquiryObject = {
      ...srfObject,
    };
    newEnquiryObject[key] = value;
    createSrfObject({ ...newEnquiryObject });
  };

  const addFollowupData = () => {
    let url = BASE_URL;
    axios
      .post(url + "enquiryHistory", srfObject)
      .then((res) => {
        getEnquiryFollowUpData();
        toast("Enquiry Followup created successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getEnquiryFollowUpData = () => {
    let url = BASE_URL;
    axios
      .get(url + `enquiryHistory?_where=(enquiryId,eq,${params.enquiryId})`)
      .then((res) => {
        setfollowUpData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `enquiryHistory/${id}`)
      .then((res) => {
        getEnquiryFollowUpData();
        toast("Enquiry History was deleted!");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getEnquiryFollowUpData();
  }, []);

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Enquiry Id *"
            size="small"
            fullWidth
            variant="outlined"
            value={srfObject.enquiryId || ""}
            onChange={(e) => {
              updateEnquiryObject("enquiryId", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remark *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateEnquiryObject("remark", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Followup Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfObject.followupDate ? new Date(srfObject.followupDate) : ""
              }
              onChange={(newValue) => {
                updateEnquiryObject(
                  "followupDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            onClick={() => addFollowupData()}
          >
            <b>ADD</b>
          </Button>
        </Toolbar>
      </Box>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Sr.No</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Followup Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Last Modified</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? followUpData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : followUpData
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.remark}</TableCell>
                  <TableCell>
                    {moment(row.followupDate).format("YYYY-MM-DD")}
                  </TableCell>

                  <TableCell>
                    {moment(row.lastModified).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      {showComponent("delete") && (
                        <Tooltip title="Delete Client" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete quotation?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            component="div"
            count={followUpData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default InvoiceFollowUp;
