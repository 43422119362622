import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "./../../global";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { showComponent } from "../helper/helpers";
import axios from "axios";

const RequestArray = [
  { id: 1, label: "Renewal" },
  { id: 2, label: "Part Replacement" },
  { id: 3, label: "Repairing" },
  { id: 3, label: "Maintaince  " },
];

const EditRequestForm = () => {
  const _clientId = localStorage.getItem("clientId");
  const [inputStates, setInputStates] = React.useState({
    _from:_clientId
  });
  const [clientList, setClientList] = React.useState([]);

  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axios
        .patch(url + `notification/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("notification  edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .post(url + "notification", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("notification  created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchNotification = () => {
    let url = BASE_URL;
    axios
      .get(url + `notification/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];
        let { lastModified, ...restDataObject } = dataObject;
        let newData = {
          ...inputStates,
          ...restDataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getClientData() {
    axios
      .get(BASE_URL + `clients`)
      .then((res) => {
        let newData = res.data;
        setClientList(newData);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }


  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchNotification();
  }, [params.id]);
  useEffect(() => {
    getClientData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Request Form
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {/* <Grid item xs={3}>
              <Autocomplete
                disabled={params.id}
                size="small"
                id="combo-box-demo"
                options={clientList}
                getOptionLabel={(option) => option.companyName}
                value={
                  clientList.find((lab) => lab.id === inputStates._to) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="To User*" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("_to", value.id);
                  } else {
                    updateInputObject("_to", "");
                  }
                }}
              />
            </Grid> */}

            <Grid item xs={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={RequestArray}
                getOptionLabel={(option) => option.label}
                value={
                  RequestArray.find((lab) => lab.id === inputStates.requestType) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Request Type *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("requestType", value.id);
                  } else {
                    updateInputObject("requestType", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={3} >
              <TextField
                id="outlined-basic"
                label="Message *"
                size="small"
                rows={5}
                multiline
                 maxRows={10}
                value={inputStates.message || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("message", e.target.value);
                }}
                style={{
                  width: '100%' 
                }}
              />
            </Grid>


          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
}

export default EditRequestForm