import * as React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./user.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect } from "react";
import { useParams } from "react-router";
import { showComponent } from "../../helper/helpers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useGetRoleInfo } from "../../login/Login";
const axios = require("axios");

const moduleList = [
  {
    id: 1,
    name: "dashboard",
  },
  {
    id: 2,
    name: "master",
  },
  {
    id: 3,
    name: "SRF",
  },
  {
    id: 4,
    name: "Datasheet",
  },
  {
    id: 5,
    name: "Certificates",
  },
  {
    id: 6,
    name: "Standard In/Out",
  },
  {
    id: 7,
    name: "Courier Register",
  },
  {
    id: 8,
    name: "Expenses",
  },
  {
    id: 9,
    name: "Work Report",
  },
  {
    id: 10,
    name: "Summary Report",
  },
  {
    id: 11,
    name: "History Card",
  },
  {
    id: 12,
    name: "Due Reports",
  },
  {
    id: 13,
    name: "Masters Due",
  },
  {
    id: 14,
    name: "Enquiry",
  },
  {
    id: 15,
    name: "Quotation",
  },
  {
    id: 16,
    name: "Invoice",
  },
  {
    id: 17,
    name: "Payment",
  },
  {
    id: 18,
    name: "Purchase",
  },
  // {
  //   "id": 19,
  //   "name": "Intermediate Procedure",
  // },
  // {
  //   "id": 20,
  //   "name": "Nable Scope"
  // },
  {
    id: 21,
    name: "Standard In/Out",
  },
  {
    id: 22,
    name: "Enquiry Followup",
  },
  {
    id: 23,
    name: "Feedback Report",
  },
  {
    id: 24,
    name: "Pickup",
  },
  {
    id: 25,
    name: "User",
  },
  {
    id: 26,
    name: "History Card",
  },
  {
    id: 27,
    name: "Audit",
  },
  {
    id: 28,
    name: "Customer Complaints",
  },
  {
    id: 29,
    name: "Customer Feedback",
  },
  {
    id: 30,
    name: "Request Form",
  },
  {
    id: 31,
    name: "Document Upload",
  },
  {
    id: 32,
    name: "Help",
  },
  {
    id: 33,
    name: "DUC Master",
  },
];

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },
  {
    id: 4,
    label: "Branch Admin",
  },
  {
    id: 2,
    label: "Engineer",
  },
];

const headers = [
  { name: "Client" },
  { name: "Address" },
  { name: "Start Date" },
  { name: "End Date" },
];

export default function EditUserMaster() {
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("");
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [executing, setExecuting] = React.useState(false);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [companies, setCompanies] = React.useState(null);
  const [companiesD, setCompaniesD] = React.useState(null);
  const [clientArray, setClientArray] = React.useState(null);
  const [clientArrayD, setClientArrayD] = React.useState(null);
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [selectedBranches, setSelectedBranch] = React.useState([]);
  // const [settingList, setSettingList] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [uroles, setURoles] = React.useState([]);
  const rolesArray = useGetRoleInfo(true, true);
  const { id } = useParams();

  const filterClients = (clients, uBranches) => {
    if (!uBranches) return clients;
    return (clients || []).filter((c) => uBranches.includes(c.branch));
  };

  const getClientIdList = (uBranches) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(filterClients(res.data, uBranches));
        setClientArrayD(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getBranches = (setBranch) => {
    axios.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    });
  };

  const onSubmit = async () => {
    setExecuting(true);
    // console.log("type : ", type);
    var jsonData = {
      userName: userName,
      password: password,
      type: type?.id,
      branch:
        type?.id !== 1
          ? (selectedBranches || []).map((b) => b.id).join(",")
          : null,
      modules:
        type?.id !== 1
          ? (selectedModules || []).map((m) => m.id).join(",")
          : null,
      // roles: roles.map((e) => e.id).join(","),
      roles: roles.join(","),
    };

    let ret = id
      ? axios.patch(BASE_URL + `users/${id}`, jsonData)
      : axios.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        if (jsonData.type == 2 && companies) {
          let rows = [
            ...companies.map((company) => {
              return {
                userId: id ? id : res.data.insertId,
                companyId: company[0].id,
                startDate: company[1]
                  ? moment(company[1]).format("YYYY-MM-DD")
                  : null,
                endDate: company[2]
                  ? moment(company[2]).format("YYYY-MM-DD")
                  : null,
                status: 1,
                id: company[3],
              };
            }),
          ];
          let post = [],
            patch = [],
            patch_id = [],
            tmp = null;
          let tmp_id = null;
          rows.map((ua) => {
            tmp = { ...ua };
            delete tmp.id;
            if (ua.id) {
              patch_id.push(ua.id);
              patch.push(tmp);
            } else {
              post.push(tmp);
            }
          });
          let url = BASE_URL;
          Promise.all([
            ...post.map((pst) => axios.post(url + "userClientAssign", pst)),
            ...patch.map((ptch, i) =>
              axios.patch(url + `userClientAssign/${patch_id[i]}`, ptch)
            ),
          ])
            .then((res2) => {
              toast.success("Updated Sucessfully!");
              refresh();
            })
            .catch((err) => {
              toast.error("Companies error : ", err);
              // console.log("Companies error   : ", err);
              setExecuting(false);
            });
        } else {
          toast.success("Updated Sucessfully!");
          refresh();
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initModules = () => {
    setModules(moduleList);
  };

  function fetchUserDetails() {
    axios.get(BASE_URL + `users/${id}`).then((res) => {
      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      setType(userTypes.filter((t) => t.id == res.data[0].type)[0]);
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules?.split(",").map((m) => moduleList[m - 1] || [])
          )
        : setSelectedModules([]);
      setURoles((res.data[0].roles || "").split(","));
      getBranches((inpt) => {
        let tmp = {};
        inpt.map((e) => (tmp[e.id] = e));
        res.data[0].branch
          ? setSelectedBranch(
              res.data[0].branch?.split(",").map((b) => tmp[b]) || []
            )
          : setSelectedBranch([]);
        getClientIdList((res.data[0].branch || "").split(","));
      });
      axios
        .get(BASE_URL + `userClientAssign?_where=(userId,eq,${id})})`)
        .then((res2) => {
          // console.log("client company mapping : ", res2.data);
          let tCompanies = [];
          setCompaniesD(res2.data);
          setUserLoaded(true);
        });
    });
  }

  useEffect(() => {
    initModules();
    if (id) fetchUserDetails();
    if (!id) getBranches(() => {});
    if (!id) getClientIdList();
  }, []);

  useEffect(() => {
    if ((id ? userLoaded : true) && companiesD && clientArray) {
      let validCompanies = companiesD.map((c1) => [
        clientArray.filter((c) => c.id == c1.companyId)[0] || null,
        c1.startDate,
        c1.endDate,
        c1.id,
      ]);
      setCompanies([...validCompanies]);
    }
  }, [companiesD, userLoaded, clientArray]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {id
          ? `Edit ${type?.label ? type?.label : ""} : ${userName}`
          : "Add User"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
          />
          <div>
            <p id="letter" class={passVal[0] ? "valid" : "invalid"}>
              A <b>lowercase</b> letter
            </p>
            <p id="capital" class={passVal[1] ? "valid" : "invalid"}>
              A <b>capital (uppercase)</b> letter
            </p>
            <p id="number" class={passVal[2] ? "valid" : "invalid"}>
              A <b>number</b>
            </p>
            <p id="lengthMin" class={passVal[3] ? "valid" : "invalid"}>
              Minimum <b>8 characters</b>
            </p>
            <p id="lengthMax" class={passVal[4] ? "valid" : "invalid"}>
              Maximum <b>32 characters</b>
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {!id && (
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={userTypes}
              value={type}
              renderInput={(params) => (
                <TextField {...params} label="User Type *" />
              )}
              onChange={(e, val) => {
                setType(val);
              }}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          {id ? (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={rolesArray}
              getOptionLabel={(option) => option.name}
              disabled
              value={
                uroles.length > 0
                  ? rolesArray.filter((r) => uroles.includes(r.id))
                  : []
              }
              multiple
              renderInput={(params) => (
                <TextField {...params} label="Roles *" />
              )}
              onChange={(event, value) => {
                setRoles(value);
                setRoles([...(value?.map((e) => e.id) || [])]);
              }}
            />
          ) : (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={rolesArray}
              getOptionLabel={(option) => option.name}
              multiple
              renderInput={(params) => (
                <TextField {...params} label="Roles *" />
              )}
              onChange={(event, value) => {
                setRoles(value);
                setRoles([...(value?.map((e) => e.id) || [])]);
              }}
            />
          )}
        </Grid>

        {type?.id !== 1 && (
          <Grid item xs={3}>
            <Autocomplete
              multiple
              fullWidth
              id="branch"
              size="small"
              options={branches || []}
              defaultValue={undefined}
              value={selectedBranches || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.branchName || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.branchName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Branch"
                  placeholder="Select Branch"
                />
              )}
              onChange={(event, value) => {
                setClientArray(
                  filterClients(
                    clientArrayD,
                    value.map((b) => `${b.id}`)
                  )
                );
                setSelectedBranch(value || []);
              }}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid item xs={id && type?.id === 2 ? 6 : 3}>
            <Autocomplete
              multiple
              fullWidth
              id="modules"
              size="small"
              options={modules || []}
              defaultValue={undefined}
              value={selectedModules || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select modules (optional)"
                  placeholder="select multiple modules"
                />
              )}
              onChange={(event, value) => setSelectedModules(value || [])}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: "16px" }}>
        {type?.id === 2 && (
          <Grid item xs={9}>
            <Box sx={{ width: "100%" }}>
              <table className="user-client-table">
                <tr>
                  {headers.map((header, id) => (
                    <th key={`header-${id}`}>{header.name}</th>
                  ))}
                  {(companies || []).length > 1 && showComponent("delete") ? (
                    <th></th>
                  ) : (
                    ""
                  )}
                </tr>
                {(companies || []).map((e, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <Autocomplete
                          fullWidth
                          id="checkboxes-tags-demo"
                          size="small"
                          value={e[0] ? e[0] : {}}
                          options={clientArray || []}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.companyName || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Client"
                              // variant="standard"
                            />
                          )}
                          onChange={(event, value) => {
                            let tmp = [...companies];
                            tmp[idx][0] = { ...value };
                            setCompanies([...tmp]);
                          }}
                          // slotProps={{ textField: { variant: "standard" } }}
                        />
                      </td>
                      <td>{e[0]?.address || ""}</td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[1] ? new Date(e[1]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][1] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      <td
                        style={
                          !e[2] || new Date(e[2]) < new Date()
                            ? { backgroundColor: "#faa" }
                            : {}
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[2] ? new Date(e[2]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][2] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      {(companies || []).length > 1 &&
                      showComponent("delete") ? (
                        <td width={"15px"}>
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              let tmp = (companies || []).filter(
                                (_, i) => i !== idx
                              );
                              axios
                                .delete(
                                  BASE_URL +
                                    `userClientAssign/${companies[idx][3]}`
                                )
                                .then((res) =>
                                  toast.success("deleted successfully!")
                                )
                                .catch((err) => {
                                  console.log("err : ", err);
                                  toast.error("err : " + err);
                                });
                              setCompanies(tmp);
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan={
                      (companies || []).length > 1
                        ? headers.length + 1
                        : headers.length
                    }
                  >
                    <Button
                      onClick={(e) => {
                        setCompanies([...(companies || []), [[], "", ""]]);
                      }}
                    >
                      <b style={{ fontSize: "18px" }}>ADD CLIENT</b>
                      <AddIcon />
                    </Button>
                  </td>
                </tr>
              </table>
            </Box>
          </Grid>
        )}
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
      {/* <ToastContainer /> */}
    </Paper>
  );
}
