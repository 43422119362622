
import * as XLSX from "xlsx";

export const importExcel = (e, readingColumns, setReadingRows, setExcelImport) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      let rows = data.split("\n");
      if (rows.length > 2) rows.shift();
      let finalRows = [];
      rows = rows.map((row) =>
        row
          .split(",")
          .filter((_, id) => id < readingColumns.length - 1)
          .join(",")
      );
      rows.forEach((row) => {
        let tmp = row.split(",");
        let tmp2 = "";
        let unit = "";
        tmp[3] = tmp[3]
          .replaceAll("To", "to")
          .split("||")
          .map((e) => {
            tmp2 = e
              .trim()
              .split(" ")
              .map((e2) => e2.trim());
            if (e.includes("to")) {
              unit = tmp2.filter((_, i) => i > 2).join(" ");
              return `${tmp2[0]}${unit ? `#${unit}` : ""}|${tmp2[2]}${
                unit ? `#${unit}` : ""
              }`;
            }
            unit = tmp2.filter((_, i) => i > 0).join(" ");
            return `${tmp2[0]}${unit ? `#${unit}` : ""}`;
          })
          .join("||");
        tmp[4] = tmp[4]
          .split("||")
          .map((e) => {
            tmp2 = e
              .trim()
              .split(" ")
              .map((e2) => e2.trim());
            return `${tmp2[0]}${
              tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""
            }`;
          })
          .join("||");
        tmp[9] = tmp[9]
          .replaceAll("±", "")
          .split("||")
          .map((e) => {
            tmp2 = e.trim().split("(");
            tmp2[0] = tmp2[0].trim().split(" ");
            tmp2[0][0] = `${tmp2[0][0]} (${tmp2[1]}`;
            tmp2[1] = tmp2[0][1];
            tmp2[0] = tmp2[0][0];
            // tmp2 = e.trim().split(" ").map(e2 => e2.trim())
            // return `${tmp2[0]}${tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""}`
            return `${tmp2[0]}${
              tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""
            }`;
          })
          .join("||");
        tmp[10] = tmp[10].length == 0 ? null : tmp[10];
        finalRows.push(tmp);
      });
      setExcelImport(true);
      setReadingRows(finalRows);
    };
    reader.readAsBinaryString(file);
  };