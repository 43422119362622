import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { BASE_URL } from "../../../global";
const axios = require("axios");

// useSrfDetails hook
const formatDate = (date) => {
  date = moment(date).format("YYYY-MM-DD");
  return new Date();
};

export const useSrfDetails = (initialState) => {
  const [srfDetails, setSrfDetails] = useState(initialState);

  const fetchSrfDetails = () => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=srf.srfs,_j,client.clients&_on1=(srf.clientId,eq,client.id)&_fields=srf.serviceReqNumber,srf.jobNumber,srf.poNumber,srf.poDate,srf.receiptDate,srf.committedDeliveryDate,srf.dcNumber,srf.dcDate,srf.entryDate,srf.description,client.address,client.id,client.companyName&_where=(srf.id,eq, ${srfDetails?.id})`
      )
      .then((res) => {
        let data = res.data[0];
        let _srfObject = {
          id: Number(srfDetails?.id || 0),
          clientId: data.client_id,
          serviceReqNumber: data.srf_serviceReqNumber,
          jobNumber: data.srf_jobNumber,
          poNumber: data.srf_poNumber,
          poDate: formatDate(data.srf_poDate),
          receiptDate: formatDate(data.srf_receiptDate),
          committedDeliveryDate: formatDate(data.srf_committedDeliveryDate),
          dcNumber: data.srf_dcNumber,
          dcDate: formatDate(data.srf_dcDate),
          entryDate: formatDate(data.srf_entryDate),
          description: data.srf_description,
          address: data.client_address,
          companyName: data.client_companyName,
        };
        setSrfDetails({ ..._srfObject });
        sessionStorage.setItem("oldSrfDetails", JSON.stringify(_srfObject));
      })
      .catch((err) =>
        console.log("Something Went Wrong at (fetchSrfDetails) : ", err)
      );
  };

  const updateSrfDetails = (details) => {
    setSrfDetails({ ...srfDetails, ...details });
  };
  return [srfDetails, updateSrfDetails, fetchSrfDetails];
};

// useSrfInstruments hook
export const fetchDescriptionRows = (id, setReadingRows) => {
  let url = BASE_URL;
  axios
    .get(url + `srfInstruments?_where=(srfsId,eq,${id})`)
    .then((res) => {
      let rows = res.data.map((row) => {
        let _row = {
          id: row.id,
          instrumentId: row.instrumentId,
          DUCID: row.DUCID,
          serialNumber: row.serialNumber,
          ranges: row.ranges,
          lc: row.lc,
          make: row.make,
          model: row.model,
          department: row.department,
          operatingRange: row.operatingRange,
          ConOfDuc: row.ConOfDuc,
          calFrequency: row.calFrequency,
          accuracy: row.accuracy,
          calibrationType: row.calibrationType,
          location: row.location,
          locationOfInstrument: row.locationOfInstrument,
          calPoint: row.calPoint,
          calMethod: row.calMethod,
          labCapabilities: row.labCapabilities, // remove from everywhere even in DB
        };

        _row = {
          ..._row,
          extraColumns: { ...JSON.parse(row?.extraColumns || {}) },
        };

        return _row;
      });
      setReadingRows(rows);
      sessionStorage.setItem("oldSrfInstruments", JSON.stringify(rows));
    })
    .catch((error) => {
      console.error("Error while fetching SRF instruments, error: ", error);
      toast.error("Error while fetching SRF instruments");
    });
};

// useInstruments hook
const initiateInstrumentList = (instrumentArray) => {
  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
        id: instrumentArray[i].id,
        discipline: instrumentArray[i].name,
        calibrationProcedureNo: instrumentArray[i].calibrationProcedureNo,
      });
    }
  }
  return pushIArray;
};

export const useInstruments = (initialState) => {
  const [instrumentArray, setInstrumentArray] = useState(initialState);
  const [instrumentList, setInstrumentList] = useState([]);

  const fetchInstrumentsList = (params) => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=ins.instruments,_j,d.discipline&_on1=(ins.disciplineId,eq,d.id)&_fields=ins.c1,ins.c2,ins.c3,ins.c4,ins.c5,ins.c6,ins.c7,ins.c8,ins.c9,ins.c10,ins.calProcRefNo,ins.calibrationProcedureNo,ins.cmcMinus,ins.cmcPlus,ins.disciplineId,ins.id,ins.instrumentName,ins.isGauge,ins.lastModified,ins.materialtype,ins.referenceStandards,ins.standardMasterIds,ins.status,ins.supportiveInstrumentMasterData,ins.uncertaintyFactors,ins.uncertaintyMasterData,d.name&_where=(ins.status,eq,1)`
      )
      .then((res) => {
        let d = JSON.stringify(res.data)
          .replaceAll("ins_", "")
          .replaceAll("d_", "");
        res.data = JSON.parse(d);
        setInstrumentArray([...res.data]);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
    if (params?.filterWithDucMaster) {
      let query = `SELECT instruments.* FROM (select distinct instrumentId from ducMaster where clientId=${params?.clientId || 0}) as ducMaster left join instruments on ducMaster.instrumentId = instruments.id;`;
      axios
        .post(BASE_URL + "dynamic", { query: query })
        .then((res) => {
          setInstrumentArray([...res.data]);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const getInstrument = (id) => {
    for (let instrument in instrumentList) {
      if (instrumentList[instrument].id == id)
        return instrumentList[instrument];
    }
    return null;
  };

  useEffect(() => {
    setInstrumentList(initiateInstrumentList(instrumentArray));
  }, [instrumentArray]);

  return [instrumentList, instrumentArray, fetchInstrumentsList, getInstrument];
};

// useClient hook
export const fetchClientIdList = (setClientArray) => {
  let url = BASE_URL;
  let userType = localStorage.getItem("type");
  let branch = localStorage.getItem("branch");
  let validClients = localStorage.getItem("validClients");
  const branchWhere = () => {
    if (branch && branch !== "null" && branch !== "undefined")
      return `~and(c.branch,in,${branch})`;
    return "";
  };
  axios
    .get(
      url +
        `xjoin?_join=c.clients,_j,b.branch&_on1=(c.branch,eq,b.id)&_fields=c.address,c.branch,c.companyName,c.contact,c.email,c.gstNumber,c.id,c.lastModified,c.matrixId,c.shippingAddress,c.signature,c.state,c.status,b.branchName&_where=(c.status,eq,1)${
          userType == 1
            ? ""
            : userType == 4
            ? `${branchWhere()}`
            : validClients?.length > 0
            ? `${branchWhere()}~and(c.id,in,${validClients})`
            : `${branchWhere()}`
        }`
    )
    .then((res) => {
      let d = (res.data = JSON.stringify(res.data)
        .replaceAll("c_", "")
        .replaceAll("b_", ""));
      res.data = JSON.parse(d);
      setClientArray(res.data);
    })
    .catch((error) => {
      toast.error("Failed to fetch client list");
      console.log({ error });
    });
};

// use unit master hook
export const useUnitMaster = (initialState) => {
  const [unitArray, setUnitArray] = useState(initialState);
  const [unitFilter, setUnitFilter] = useState([]);

  const fetchUnitMaster = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray([""].concat(res.data));
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < unitArray.length; i++) {
      uArr.push({
        label: unitArray[i].id + ", " + unitArray[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    UnitList();
  }, [unitArray]);

  return [unitArray, unitFilter, setUnitFilter, fetchUnitMaster];
};

export const useSettings = (initialState) => {
  const [settings, setSettings] = useState(initialState);

  const fetchSettings = (keys) => {
    keys = keys.map((key) => `(keyName,eq,${key})`).join("~or");
    let url = BASE_URL;
    axios
      .get(
        url +
          `settings?_where=(${keys})~and((status,eq,1)~or(status,eq,-2))&_fields=id,keyName,value`
      )
      .then((res) => {
        let data = {};
        res.data.forEach((e) => {
          data[e.keyName] = e;
        });

        setSettings({
          ...settings,
          ...data,
        });
      })
      .catch((error) => {
        toast.error("Failed to fetch settings");
      });
  };

  return [settings, fetchSettings];
};
