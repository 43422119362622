export const fixedColumns = [
  {
    field: "id",
    key: "id",
    headerName: "Sr. No.",
    editable: false,
  },
  {
    field: "description",
    key: "instrumentId",
    headerName: "Instrument *",
    editable: true,
  },
  {
    field: "DUCID",
    key: "DUCID",
    headerName: "DUCID",
    editable: true,
  },
  {
    field: "serialNumber",
    key: "serialNumber",
    headerName: "Serial number",
    editable: true,
  },
  {
    field: "range",
    key: "ranges",
    headerName: "DUC Range *",
    editable: true,
  },
  {
    field: "lc",
    key: "lc",
    headerName: "Least Count *",
    editable: true,
  },
  {
    field: "make",
    key: "make",
    headerName: "DUC Make",
    editable: true,
  },
  {
    field: "model",
    key: "model",
    headerName: "Model",
    editable: true,
  },
  {
    field: "conOfDuc",
    key: "ConOfDuc",
    headerName: "Con. Of Duc",
    editable: true,
  },
  {
    field: "calibrationFrequency",
    key: "calFrequency",
    headerName: "Calibration frequency *",
    editable: true,
  },
  {
    field: "accuracy",
    key: "accuracy",
    headerName: "Accuracy",
    editable: true,
  },
  {
    field: "calibrationType",
    key: "calibrationType",
    headerName: "Calibration Type",
    editable: true,
  },
  {
    field: "location",
    key: "location",
    headerName: "Location *",
    editable: true,
  },
  {
    field: "locationOfInstrument",
    key: "locationOfInstrument",
    headerName: "Location Of Instrument",
    editable: true,
  },
  {
    field: "calPoint",
    key: "calPoint",
    headerName: "Calibration Point",
    editable: true,
  },
  {
    field: "calMethod",
    key: "calMethod",
    headerName: "Calibration Method",
    editable: true,
  }
  ,
  {
    field: "department",
    key: "department",
    headerName: "Department ",
    editable: true,
  }
  ,
  {
    field: "operatingRange",
    key: "operatingRange",
    headerName: "Operating Range",
    editable: true,
  }
];

export const srfFields = [
  ["receiptDate"],
  ["dcNumber"],
  ["dcDate", "date"],
  ["entryDate", "date"],
  ["committedDeliveryDate", "date"],
  ["serviceReqNumber"],
  ["jobNumber"],
  ["description"],
  ["address"],
  ["poNumber"],
  ["poDate"],
];

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "30px",
  textAlign: "center",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "column",
};

export const multiValueModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    maxHeight: "350px",
    overflow: "auto",
  };