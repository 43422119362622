//po

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const CreatePO_Acceptance = () => {
  const [quotationData, setquotationData] = useState([]);
  const [clientId, setSelectedClientId] = useState(null);
  const [quotationNumber, setQuotationNumber] = useState("");
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [quotationDate, setQuotationDate] = useState("");
  const [poFileUrl, setpoFileUrl] = useState("");
  const [poAacceptanceData, createPoAacceptance] = useState({});
  const params = useParams();

  const updateSrfObject = (key, value) => {
    let newPoAacceptance = {
      ...poAacceptanceData,
    };
    newPoAacceptance[key] = value;
    createPoAacceptance({ ...newPoAacceptance });
  };

  const getquotationData = () => {
    let url = BASE_URL;
    let data = {
      query:
        "SELECT * FROM quotation WHERE status = 1 AND id NOT IN (SELECT quotationId FROM poAcceptance)",
    };
    axios
      .post(url + `dynamic`, data)
      .then((res) => {
        setquotationData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchPoData = () => {
    let url = BASE_URL;
    axios
      .get(url + `poAcceptance/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...poAacceptanceData,
          ...dataObject,
        };
        dataObject && createPoAacceptance(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axios
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setpoFileUrl(res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  const addPoAcceptance = () => {
    const payload = {
      quotationDate: params.id
      ? moment(poAacceptanceData?.quotationDate).format("YYYY-MM-DD")
      : moment(quotationDate).format("YYYY-MM-DD"),
      quotationId: params.id
      ? poAacceptanceData?.quotationId :selectedQuotationId,
      poFileUrl: poFileUrl,
      poNumber: poAacceptanceData.poNumber,
      poDate:  moment(poAacceptanceData.poDate).format("YYYY-MM-DD") ,
      amount: poAacceptanceData.amount,
    };

    let url = BASE_URL;

      if (params.id) {
        axios
          .patch(url + `poAcceptance/${params.id}`, payload)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("poAcceptance edit successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      } else {
        axios
          .post(url + "poAcceptance", payload)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("poAcceptance created successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      }
  };

  useEffect(() => {
    getquotationData();
  }, []);

  useEffect(() => {
    if (params.id) fetchPoData();
  }, [params.id]);

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          {params.id ? (
            <TextField
              {...params}
              label="Quotation ID *"
              disabled
              value={poAacceptanceData?.quotationId || ""}
            />
          ) : (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={quotationData}
              getOptionLabel={(option) => option.quotationNumber}
              renderInput={(params) => (
                <TextField {...params} label="Quotation Number *" />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  const selectedQuotation = quotationData.find(
                    (quotation) =>
                      quotation.quotationNumber === newValue.quotationNumber
                  );
                  if (selectedQuotation) {
                    setQuotationNumber(selectedQuotation.quotationNumber);
                    setQuotationDate(selectedQuotation.date);
                    setSelectedQuotationId(selectedQuotation.id);
                    setSelectedClientId(selectedQuotation.clientId);
                  } else {
                    setQuotationNumber("");
                    setQuotationDate("");
                    setSelectedQuotationId("");
                    setSelectedClientId("");
                  }
                } else {
                  setQuotationNumber("");
                  setQuotationDate("");
                  setSelectedQuotationId("");
                  setSelectedClientId("");
                }
              }}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Date"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            value={
              params.id
                ? moment(poAacceptanceData?.quotationDate).format("YYYY-MM-DD")
                : moment(quotationDate).format("YYYY-MM-DD") || null
            }
            onChange={(e) =>
              setQuotationDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Po Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={poAacceptanceData?.poNumber || ""}
            onChange={(e) => {
              updateSrfObject("poNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Po Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                poAacceptanceData.poDate
                  ? new Date(poAacceptanceData.poDate)
                  : null
              }
              onChange={(newValue) => {
                updateSrfObject(
                  "poDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Amount *"
            size="small"
            fullWidth
            variant="outlined"
            value={poAacceptanceData?.amount || ""}
            onChange={(e) => {
              updateSrfObject("amount", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Input
            id="file-upload-input"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e)}
          />
          <label htmlFor="file-upload-input">
            <Button variant="contained" component="span">
              Upload File
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary">
            Select a file to upload
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            onClick={() => addPoAcceptance()}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Box>
    </div>
  );
};

export default CreatePO_Acceptance;
