import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { ClassicTable } from "../../../utils/components/Styles";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "../../../global";
import { resolveSource } from "../utils";
const axios = require("axios");

export default function EditTypeB(props) {
  let {
    datasheetReadingId,
    datasheetId,
    instrumentId,
    lc,
    nominalVal,
    stdRangeIds,
    supportiveMaster,
    ucUnit,
  } = useParams();
  if (props.viewAll === true) {
    datasheetReadingId = props.datasheetReadingId;
    datasheetId = props.datasheetId;
    instrumentId = props.instrumentId;
    lc = props.lc;
    nominalVal = props.nominalVal;
    stdRangeIds = props.stdRangeIds;
    supportiveMaster = props.supportiveMaster;
    ucUnit = props.ucUnit;
  }

  ucUnit = (ucUnit || "").replaceAll("__", "/");
  stdRangeIds = [[...(stdRangeIds || "").split("_")], [], [], {}];
  stdRangeIds[0].map((stdR) => {
    stdRangeIds[1].push(stdR.split(":")[0]);
    stdRangeIds[2].push(stdR.split(":")[1]);
  });
  stdRangeIds[1].map((r, i) => (stdRangeIds[3][r] = stdRangeIds[2][i]));
  delete stdRangeIds[0];

  const [factorRows, setFactorRows] = useState([]);
  const [data, setData] = useState({
    typeBId: null,
    customValues: null,
    derivedValues: null,
    standards: null,
    uncertaintyFactors: null,
    filteredUncertaintyFactors: null,
    uncertaintyFactorsMap: null,
    supportiveInstruments: null,
    staticTables: null,
    customRels: null,
    staticReading: null,
    masters: null,
    supportive: null,
    set: false,
  });

  const printComponentRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const errorFn = (errTxt, err) => {
    console.error(errTxt, err);
    return ["err", err];
  };

  // data fetching
  const fetchData = () => {
    let tData = { ...data };
    Promise.all([
      fetchTypeB(),
      fetchTypeBValues(),
      fetchInstrument(),
      fetchDatasheetStaticTables(),
      fetchStandards(),
      fetchStandardMasters(),
    ]).then((res) => {
      Promise.all([
        fetchUncertaintyFactors(
          res[2][0].uncertaintyFactors
            .split("|")
            .filter((e) => stdRangeIds[1].includes(e.split(":")[0]))
            .map((e) => e.split(":")[1])
            .join(",")
        ),
        fetchSupportiveInstruments(),
        fetchSuppotiveInsrumentRanges(
          (res[2][0].supportiveInstrumentMasterData || "")
            ?.split(",")
            .map((e) => e.split(":")[0])
        ),
      ]).then((res2) => {
        if (res[0][0] !== "err") {
          tData.typeBId = res[0][0];
          tData.customValues = res[0][1];
        }
        if (res[1][0] !== "err") {
          tData.derivedValues = res[1][0];
          tData.staticReading = res[1][1];
        }
        if (res[2][0] !== "err") {
          let nMap = {};
          let master = "";
          let uArr = "";
          res[2][0].uncertaintyFactors.split("|").map((um) => {
            master = um.split(":")[0];
            uArr = um.split(":")[1].split(",");
            uArr.map((u) => {
              if (nMap[u]) nMap[u].push(master);
              else nMap[u] = [master];
            });
          });
          tData.uncertaintyFactorsMap = { ...nMap };
        }
        if (res2[0][0] !== "err") {
          tData.uncertaintyFactors = res2[0][0];
        }
        if (res2[1][0] !== "err") {
          tData.supportiveInstruments = res2[1][0];
        }
        if (res2[2][0] !== "err") {
          let ss = {};
          res2[2][0].map((s) => (ss[s.id] = s.standardName));
          tData.supportive = ss;
        }
        if (res[3][0] !== "err") {
          tData.staticTables = res[3][0];
        }
        if (res[4][0] !== "err") {
          tData.standards = res[4][0];
        }
        if (res[5][0] !== "err") {
          let ms = {};
          res[5][0].map((m) => (ms[m.sr_id] = m));
          tData.masters = ms;
        }
        if (res[3][0] && res[1][0]) {
          let rels = JSON.parse(res[3][0].defaultConfiguration);
          rels = rels?.typeb?.relations
            ? Object.entries(rels.typeb.relations)
            : [];
          let newRels = {};
          rels.map((rel) => {
            newRels[rel[1]] = res[1][0][rel[0]];
          });
          tData.customRels = newRels;
        }
        if (res2[0][0] !== "err") {
          tData.filteredUncertaintyFactors = res2[0][0];
        }
        tData.set = true;
        console.log("res data : ", res, tData);
        setData({ ...tData });
      });
    });
  };

  function fetchTypeB() {
    return axios
      .get(
        BASE_URL + `typeB?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) =>
        res.data.length
          ? [res.data[0].id, JSON.parse(res.data[0].customValues || {})]
          : [-1, {}]
      )
      .catch((err) => errorFn("typeB data fetching error: ", err));
  }

  function fetchTypeBValues() {
    return axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(id,eq,${datasheetReadingId})`
      )
      .then((res) =>
        res.data.length
          ? [JSON.parse(res.data[0]?.typeBValues), res.data[0]]
          : [{}, {}]
      )
      .catch((err) => errorFn("datasheet data fetching error: ", err));
  }

  function fetchInstrument() {
    return axios
      .get(
        BASE_URL +
          `instruments?_fields=uncertaintyFactors,supportiveInstrumentMasterData,&_where=(id,eq,${instrumentId})`
      )
      .then((res) => [res.data[0]])
      .catch((err) => errorFn("instrument data fetching error : ", err));
  }

  const fetchSupportiveInstruments = () => {
    return axios
      .get(
        BASE_URL +
          `standardRanges?_where=(id,in,${supportiveMaster
            .split(",")
            .map((e) => e.split(":")[1])
            .join(",")})`
      )
      .then((res) => [res.data])
      .catch((err) => errorFn("supportiveInstruments fetching error: ", err));
  };

  const fetchSuppotiveInsrumentRanges = (ids) => {
    return axios
      .get(
        BASE_URL +
          `standards?_where=(id,in,${ids?.toString()})&_fields=standardName,id`
      )
      .then((res) => [res.data])
      .catch((err) =>
        errorFn("supportiveInstrumentRanges fetching error: ", err)
      );
  };

  function fetchUncertaintyFactors(uf) {
    return axios
      .get(BASE_URL + `uncertainty?_where=(id,in,${uf?.toString()})`)
      .then((res) => [res.data])
      .catch((err) =>
        errorFn("uncertainty factors data fetching error: ", err)
      );
  }

  function fetchDatasheetStaticTables() {
    let url = BASE_URL;
    return axios
      .get(
        url + `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => [res.data[0]])
      .catch((err) =>
        errorFn("datasheet static tables data fetching error: ", err)
      );
  }

  function fetchStandards() {
    return axios
      .get(
        BASE_URL +
          `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_fields=s.standardName,sr.id,s.id&_where=(s.status,eq,1)~and(sr.id,in,${stdRangeIds[2]})`
      )
      .then((res) => [res.data])
      .catch((err) => errorFn("standard ranges data fetching error: ", err));
  }

  const fetchStandardMasters = () => {
    return axios
      .get(
        BASE_URL +
          `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_fields=s.standardName,sr.id,s.id&_where=(s.status,eq,1)~and(sr.id,in,${stdRangeIds[2]})`
      )
      .then((res) => [res.data])
      .catch((err) => errorFn("supportiveInstruments fetching error: ", err));
  };

  function submitTypeB() {
    if (!data.typeBId) {
      axios
        .post(BASE_URL + `typeB`, {
          datasheetReadingId: datasheetReadingId,
          customValues: JSON.stringify(data.customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .patch(BASE_URL + `typeB/${data.typeBId}`, {
          customValues: JSON.stringify(data.customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (data.set) renderFields();
  }, [data]);

  const updateCustomValue = (uCount, value) => {
    setData({
      ...data,
      customValues: {
        ...data.customValues,
        [`${uCount}`]: value,
      },
    });
  };

  const renderFields = async () => {
    let selectors = {
      datasheets: datasheetId,
      instruments: instrumentId,
      datasheetStaticReadings: datasheetReadingId,
    };
    let rows = [];
    let uCount = 1;

    for (let index = 0; index < data.standards?.length; index++) {
      for (let i = 0; i < data.filteredUncertaintyFactors?.length; i++) {
        //check if uncertainty is selected for masster
        if (
          !data.uncertaintyFactorsMap[
            data.filteredUncertaintyFactors[i].id
          ].includes(`${data.standards[index].s_id}`)
        )
          continue;

        let isDerived = false;
        let dv = null;
        // derived value from dtasheet
        if (data.derivedValues?.[data.filteredUncertaintyFactors[i].id]) {
          dv = data.derivedValues[data.filteredUncertaintyFactors[i].id];
          isDerived = true;
        }

        if (data.customValues?.["s:" + index + ":" + (i + 1)]) {
          dv = data.customValues["s:" + index + ":" + (i + 1)];
          dv = Number((String(dv)?.match(/[\d\.]+/g) || [])[0]);
          dv = String(dv).replace(",", " ");
        }

        if (data.customRels?.[data.filteredUncertaintyFactors[i].id]) {
          dv = data.customRels[data.filteredUncertaintyFactors[i].id];
        }

        let value = dv;
        if (isDerived == false) {
          selectors.standards = data.standards[index].s_id;
          selectors.standardRanges = data.standards[index].sr_id;
          value = await resolveSource(
            JSON.parse(data.filteredUncertaintyFactors[i].sourceconfig || null),
            selectors,
            ucUnit,
            data.filteredUncertaintyFactors[i].id
          );
          value = value + "#" + ucUnit.split("_unit_")?.[1];
        }

        let row = (
          <TableRow key="1">
            <TableCell>{"U" + uCount}</TableCell>
            <TableCell>
              {`${data.filteredUncertaintyFactors[i].name}${
                false ? `(${data.standards[index]?.["s_standardName"]})` : ""
              }`}
              <br />±{(value || "").split("#")[0]}
              <b style={{ padding: "auto" }}>{`  ${(
                (value || "")?.split("#")[1] || ""
              )?.replaceAll("undefined", "")}  `}</b>
            </TableCell>
            <TableCell>
              {data.filteredUncertaintyFactors[i].distribution}
            </TableCell>
            <TableCell>ω</TableCell>
            <TableCell>Sensitivity Coeff. = 1</TableCell>
            <TableCell>
              {(value || "").split("#")[0]}
              <b style={{ padding: "auto" }}>{`  ${(
                (value || "")?.split("#")[1] || ""
              )?.replaceAll("undefined", "")}  `}</b>
            </TableCell>
          </TableRow>
        );
        rows.push(row);
        uCount++;
      }
    }

    let parameter = ["axialUniformity", "radialUniformity", "stability"];
    data.supportiveInstruments?.map((factor, index) => {
      for (let i = 0; i < parameter.length; i++) {
        if (factor[parameter[i]] != "" && factor[parameter[i]] != null) {
          let dv = factor[parameter[i]];
          if (data.customValues["si:" + index + ":" + (i + 1)]) {
            dv = data.customValues["si:" + index + ":" + (i + 1)];
          }
          let row = (
            <TableRow key="1">
              <TableCell>{"U" + uCount}</TableCell>
              <TableCell>
                {`${parameter[i]} (${data.supportive[factor.standardId]})`}
                <br />±{(dv || "")?.split("#")[0]}
                <b style={{ padding: "auto" }}>{`  ${(
                  (dv || "")?.split("#")[1] || ""
                )?.replaceAll("undefined", "")}  `}</b>
              </TableCell>
              <TableCell>{"1/√3"}</TableCell>
              <TableCell>ω</TableCell>
              <TableCell>Sensitivity Coeff. = 1</TableCell>
              <TableCell>
                ±{(factor[parameter[i]] || "")?.split("#")[0]}
                <b style={{ padding: "auto" }}>{`  ${(
                  (factor[parameter[i]] || "")?.split("#")[1] || ""
                )?.replaceAll("undefined", "")}  `}</b>
              </TableCell>
            </TableRow>
          );
          rows.push(row);
          uCount++;
        }
      }
    });

    setFactorRows([...rows]);
  };

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" align="center" component="div" sx={{ mb: 2 }}>
        Type-B Contribution
      </Typography>

      <div>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Distribution</TableCell>
                <TableCell>DOF</TableCell>
                <TableCell>Calculation</TableCell>
                <TableCell>Value(±)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.standards &&
                data.filteredUncertaintyFactors &&
                factorRows?.map((row) => row)}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      {props.viewAll != true && (
        <Toolbar
          sx={{ displayPrint: "none" }}
          style={{ padding: "0px", width: "100%" }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3, displayPrint: "none" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Toolbar>
      )}
    </Paper>
  );
}
