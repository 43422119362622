import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { showComponent } from "../../helper/helpers";
import axios from "axios";
import MultiValueInputModal from "../../common/multiValueInputModal";


const calArray = [
  { id: 1, label: "3 Months" },
  { id: 2, label: "6 Months" },
  { id: 3, label: "12 Months" },
  { id: 3, label: "18 Months" },
  { id: 4, label: "24 Months" },
];

const modalConfigs = [
  {
    buttonLabel: "Range",
    transformedStringLabel: "ranges",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "lc",
  },
  {
    buttonLabel: "Operating Range",
    transformedStringLabel: "operatingRange",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "accuracy",
  },
];

const EditDucMaster = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [clientList, setClientList] = React.useState([]);
  const [instrumentstList, setInstrumentstList] = React.useState([]);

  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axios
        .patch(url + `ducMaster/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ducMaster  edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .post(url + "ducMaster", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ducMaster  created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchDucMaster = () => {
    let url = BASE_URL;
    axios
      .get(url + `ducMaster/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];
        let { lastModified, ...restDataObject } = dataObject;
        let newData = {
          ...inputStates,
          ...restDataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getClientData() {
    axios
      .get(BASE_URL + `clients?_where=(status,eq,1)`)
      .then((res) => {
        let newData = res.data;
        setClientList(newData);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  function getInstrumentstData() {
    axios
      .get(BASE_URL + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        let newData = res.data;
        setInstrumentstList(newData);
      })
      .catch((err) => {
        console.log("instrument_master data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchDucMaster();
  }, [params.id]);
  useEffect(() => {
    getClientData();
    getInstrumentstData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update DUC Master
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={3}>
              <Autocomplete
                disabled={params.id}
                size="small"
                id="combo-box-demo"
                options={clientList}
                getOptionLabel={(option) => option.companyName}
                value={
                  clientList.find((lab) => lab.id === inputStates.clientId) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Company Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("clientId", value.id);
                  } else {
                    updateInputObject("clientId", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={instrumentstList}
                getOptionLabel={(option) => option.instrumentName}
                value={
                  instrumentstList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrumentst Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                  } else {
                    updateInputObject("instrumentId", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="DUCID *"
                size="small"
                value={inputStates.DUCID || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("DUCID", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Serial Number *"
                size="small"
                value={inputStates.serialNumber || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("serialNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Make *"
                size="small"
                fullWidth
                variant="outlined"
                value={inputStates.make || ""}
                onChange={(e) => {
                  updateInputObject("make", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Model *"
                size="small"
                value={inputStates.model || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("model", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Location Of Instrument *"
                size="small"
                value={inputStates.locationOfInstrument || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("locationOfInstrument", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Department *"
                size="small"
                value={inputStates.department || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("department", e.target.value);
                }}
              />
            </Grid>


            <Grid item xs={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={calArray}
                getOptionLabel={(option) => option.label}
                value={
                  calArray.find((lab) => lab.id === inputStates.calFrequency) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Cal Frequency *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("calFrequency", value.id);
                  } else {
                    updateInputObject("calFrequency", "");
                  }
                }}
              />
            </Grid>

            <Grid item spacing={3}>
              <div style={{ display: "flex", gap: "50px" }}>
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditDucMaster;
