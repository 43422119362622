import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { Grid, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Typography, Modal } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { showComponent } from "../helper/helpers";
import EmailIcon from "@mui/icons-material/Email";
import TextEditor from "./textEditor";
import VerifiedIcon from "@mui/icons-material/Verified";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@mui/icons-material/Description";

import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { green, red, orange } from "@material-ui/core/colors";
import { checkDSStatusByCount } from "./countTester";

const getStatusColor = (status) =>
  status === "yes" ? green[500] : orange[500];

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");
const columns = [
  { id: "id", label: "ID", minWidth: 80 },
  { id: "customerName", label: "Company Name", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 200 },
  {
    id: "serviceReqNumber",
    label: "Service Req Number",
    minWidth: 40,
    align: "left",
  },

  {
    id: "commitedDate",
    label: "Commited delivery Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "issueDate",
    label: "Issue Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "userName",
    label: "Created by",
    minWidth: 40,
    align: "left",
  },
];

const useStyles = makeStyles({
  timeline: {
    transform: "rotate(270deg)",
  },
  timelineContentContainer: {
    textAlign: "left",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  success: {
    color: "green",
  },
  danger: {
    color: "orange",
  },
});

function createData(
  id,
  customerName,
  address,
  serviceReqNumber,
  commitedDate,
  issueDate,
  clientId,
  userName,
  dcNumber,
  dcDate,
  entryDate,
  receiptDate
) {
  return {
    id,
    customerName,
    address,
    serviceReqNumber,
    commitedDate,
    issueDate,
    clientId,
    userName,
    dcNumber,
    dcDate,
    entryDate,
    receiptDate,
  };
}

export default function SrfsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [srfList, setSrfList] = React.useState([]);
  const [dBStatus, setDBStatus] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const validCompanies = localStorage.getItem("validClients");
  const [open, setOpen] = React.useState(false);
  const userName = localStorage.getItem("userName");
  const [selectedColumn, SetSelectedColumn] = React.useState([]);
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [srfRowId, setsrfRowId] = React.useState(null);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [srfNumber, setSrfNumber] = React.useState("");
  const [invoiceStatus, setInvoiceStatus] = React.useState("");
  const [srfAnalyticsData, setsrfAnalyticsData] = React.useState([]);
  const classes = useStyles();
  const handleOpenTimeline = (id, customerName, serviceReqNumber) => {
    //row.id,row.customerName,row.serviceReqNumber
    updateSRFAnalytics(id);
    setCompanyName(customerName);
    setSrfNumber(serviceReqNumber);
    setOpenTimeline(true);
    setsrfRowId(id);
  };

  const handleCloseimeline = () => {
    setOpenTimeline(false);
  };

  const [formData, setFormData] = React.useState({
    userEmail: "",
    clientName: userName ? userName.toUpperCase() : "",
    subject: "",
  });

  const [editorValue, setEditorValue] = React.useState(null);

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "pr.companyName",
        "service Request Number": "serviceReqNumber",
      }[searchBy];
      whereConditions = ` ${colName} like '%${searchKey
        .replaceAll("/", "_")
        .replaceAll("#", "_")}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` pl.entryDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` pr.branch in (${branch})`
        : "";

    whereConditions += (whereConditions ? " and " : "") + " pl.status = 1 ";

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (pl.createdby=${userId}`
          : ` where (pl.createdby=${userId}`;
      whereConditions +=
        whereConditions.length > 0
          ? ` or pr.id in (${validCompanies}))`
          : ` where pr.id in (${validCompanies})`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select pl.clientId,pr.companyName,pl.serviceReqNumber,pl.committedDeliveryDate,pl.receiptDate,pl.id,pl.address,pl.entryDate,u.userName from srfs as pl left join clients as pr on pl.clientId = pr.id left join users as u on u.id=pl.createdby  ${
        whereConditions ? "where" + whereConditions : ""
      } order by pl.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(*) as no_of_rows from srfs as pl left join clients as pr on pl.clientId = pr.id left join users as u on u.id=pl.createdby  ${
        whereConditions ? "where" + whereConditions : ""
      } order by pl.id DESC ${pagination_settings}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    // var data = {
    //   query:
    //     userType == "2"
    //       ? `select count(*) as no_of_rows from srfs where createdby=${userId}`
    //       : `select count(*) as no_of_rows from srfs`,
    // };
    let data = getQuery(true);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function getSrfsList() {
    let data = getQuery();
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setSrfList([...res.data]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const search = () => {
    getSrfsList();
  };
  function getSrfAnalytics() {
    const url = `${BASE_URL}dynamic`;

    const Payload = {
      query: `SELECT srfAnalytics.*, srfInstruments.instrumentId FROM srfAnalytics LEFT JOIN srfInstruments ON srfAnalytics.srfInstrumentId = srfInstruments.id WHERE srfAnalytics.srfId =${srfRowId}`,
    };
    axios
      .post(url, Payload)
      .then((res) => {
        setsrfAnalyticsData(res.data);
      })
      .catch((err) => console.log(err));
  }
  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  useEffect(() => {
    getSrfAnalytics();
  }, [srfRowId]);

  useEffect(() => {
    checkDSStatusByCount(setDBStatus);
    getTotalRows();
    getInstrumentsList();
  }, []);

  useEffect(() => {
    getSrfsList();
  }, [rowsPerPage, page]);

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${id})&_fields=id`)
      .then((res) => {
        Promise.all([
          axios.delete(url + `srfs/${id}`),
          ...res.data.map((d) => axios.delete(url + `datasheets/${d.id}`)),
          ...res.data.map((d) => axios.delete(url + `certificates/${d.id}`)),
          ...res.data.map((d) => axios.delete(url + `srfInstruments/${d.id}`)),
        ])
          .then((res) => {
            toast("Srf deleted successfully!");
            setTimeout(refresh, 1000);
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  var rowDaata = [];
  for (let i = 0; i < srfList.length; i++) {
    rowDaata.push(
      createData(
        srfList[i].id,
        srfList[i].companyName,
        srfList[i].address,
        srfList[i].serviceReqNumber,
        moment(srfList[i].committedDeliveryDate).format("DD-MM-YYYY"),
        moment(srfList[i].receiptDate).format("DD-MM-YYYY"),
        srfList[i].clientId,
        srfList[i].userName,
        srfList[i].receiptDate,
        srfList[i].dcNumber,
        moment(srfList[i].dcDate).format("DD-MM-YYYY"),
        moment(srfList[i].entryDate).format("DD-MM-YYYY")
      )
    );
  }

  /////////////////////////////////// Mail ///////////////////////////////////////

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        userEmail: formData.userEmail,
        clientName: formData.clientName,
        subject: formData.subject,
        description: editorValue,
      };

      axios
        .post("http://localhost:5000/api/email", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Email sent successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong Failed to send email !");
        });

      handleClose();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };
  const renderEmailModal = () => {
    let DescriptionValue = `<p><strong>Dear Sir/Madam,</strong></p><br><p>Greetings from <strong>Bhavid Technology Private Limited, Rajkot!</strong></p><p>Thank you for your inquiry! Please find attached a Calibration Quotation to suit your business requirements.</p><p>Bhavid Technology Private Limited (BTPL) is Region’s Largest NABL Accredited - ISO 17025:2017 certified Calibration Laboratory. We have Technically, the best talent and infrastructural support to serve all your needs.</p><p> Please visit www.bhavidtech.com to get to know about our state-of-the-art facility for Calibration Services.</p><p>Refer to your friends and colleagues | Register us as your preferred External Provider | Consider this as our invitation to visit our state-of-the-art Laboratory.</p><br/><p>Thanks & Regards,</p><p>For - Bhavid Technology Private Limited.</p><p>Registered Office: 173, Kasturi Residency,Ambika Township,</p><p>Jivraj Park, Nana Mava Main Road,  Rajkot – 360005.</p><p>Contact No: 917046477077 &nbsp; Email: <a href=\"mailto:inquiry@bhavidtech.com/\">inquiry@bhavidtech.com/</a></p><p>Website: <a href=\"http://bhavidtech.com/\" rel=\"noopener noreferrer\" target=\"_blank\">bhavidtech.com/</a></p>`;

    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 800,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" mb={2}>
            Email Form
          </Typography>
          <TextField
            label="User Email"
            fullWidth
            name="userEmail"
            value={formData.userEmail}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Client Name"
            fullWidth
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Subject"
            fullWidth
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />

          <TextEditor
            value={editorValue || DescriptionValue}
            onChange={handleEditorChange}
          />
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Box>
      </Modal>
    );
  };

  const renderTimeline = () => {
    //srfAnalyticsData
    function getStatusObject(progressPercent) {
      if (progressPercent === null) {
        return {
          inwordStatus: "no",
          dataSheetStatus: "no",
          approveStatus: "no",
          dispatchedStatus: "no",
        };
      }

      const inwordStatus = progressPercent >= 25 ? "yes" : "no";
      const dataSheetStatus = progressPercent >= 50 ? "yes" : "no";
      const approveStatus = progressPercent >= 75 ? "yes" : "no";
      const dispatchedStatus = progressPercent >= 100 ? "yes" : "no";

      return {
        inwordStatus,
        dataSheetStatus,
        approveStatus,
        dispatchedStatus,
      };
    }

    const statusObjects = srfAnalyticsData.map((item) => ({
      id: item.id,
      srfId: item.srfId,
      srfInstrumentId: item.srfInstrumentId,
      status: getStatusObject(item.progressPercent),
      instrumentId: item.instrumentId,
    }));
    let data = {
      query: `SELECT CASE WHEN EXISTS (SELECT 1 FROM customInvoice WHERE serviceReqNumber = '${srfNumber}') THEN 'yes' ELSE 'no' END AS Response`,
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setInvoiceStatus(res.data[0].Response);
      })
      .catch((err) => {
        console.log("InvoiceStatus data fetching error: ", err);
      });

    return (
      <Modal open={openTimeline} onClose={handleCloseimeline}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 800,
            height: 550,
            overflowY: "scroll",
          }}
        >
          <TableContainer component={Paper}>
            <Box>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  Company Name:
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="span">{companyName}</Typography>
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  SRF Number:
                </Typography>
                &nbsp;&nbsp;<Typography variant="span">{srfNumber}</Typography>
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  Invoice Status:
                </Typography>
                &nbsp;&nbsp;
                <Typography
                  variant="span"
                  style={{ color: getStatusColor(invoiceStatus) }}
                >
                  {invoiceStatus == "yes" ? (
                    <VerifiedIcon />
                  ) : (
                    <DangerousIcon />
                  )}
                </Typography>
              </Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Instrument Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Inword Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    DataSheet Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Approve Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Dispatched Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusObjects.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {item.instrumentId
                        ? pushIArray
                            .filter(
                              (e) =>
                                e?.label?.split(",")[0] == item?.instrumentId
                            )?.[0]
                            ?.label.split(",")[1]
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.inwordStatus),
                      }}
                    >
                      {item.status.inwordStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dataSheetStatus),
                      }}
                    >
                      {item.status.dataSheetStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.approveStatus),
                      }}
                    >
                      {item.status.approveStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dispatchedStatus),
                      }}
                    >
                      {item.status.dispatchedStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      {/* {dBStatus === "sick" ? (
        <h1 style={{ color: "red" }}>
          {" "}
          Record count is mismatching.
          <br /> please contact the support team. Tel: +91 9769847865
        </h1>
      ) : null} */}
      {/* {dBStatus !== "sick" && ( */}
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          {renderEmailModal()}
          <br />
          <div>
            <Toolbar>
              <Button
                variant="contained"
                size="small"
                component={Link}
                to="/srf/editSRF/0"
              >
                <b>create srf</b>
              </Button>
            </Toolbar>
          </div>
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                classNames="mr-2"
              >
                <DatePicker
                  label="From"
                  value={from ? new Date(from) : ""}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => setFrom(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div style={{ marginRight: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  value={to ? new Date(to) : ""}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => setTo(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  style={{ marginRight: "20px" }}
                />
              </LocalizationProvider>
            </div>

            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[
                { key: "serviceReqNumber", label: "service Request Number" },
                { key: "address", label: "Address" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <SearchIcon />
            </Button>
          </div>
          <br />

          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            size="small"
            id="datatable-keytable"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
                <TableCell>
                  <b>Progress List</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowDaata
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.id == "id") {
                          value = index + 1;
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <Button
                          component={Link}
                          onClick={() =>
                            handleOpenTimeline(
                              row.id,
                              row.customerName,
                              row.serviceReqNumber
                            )
                          }
                        >
                          Progress
                        </Button>
                      </TableCell>
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip title="Edit SRF" placement="top-start">
                            <Button
                              component={Link}
                              to={`/srf/editSrf/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Send Email" placement="top-start">
                            <Button
                              onClick={() => {
                                SetSelectedColumn(row);
                                handleOpen();
                              }}
                            >
                              <EmailIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Dispatch" placement="top-start">
                            <Button
                              component={Link}
                              to={`/srf/dispatch/${row.id}/${row.clientId}`}
                            >
                              <SendTimeExtensionIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            title="Dispatch Status"
                            placement="top-start"
                          >
                            <Button
                              component={Link}
                              to={`/srf/DispatchStatus/${row.id}`}
                            >
                              <DescriptionIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Print Srf" placement="top-start">
                            <Button
                              component={Link}
                              to={`/srf/viewSrf/${row.id}`}
                            >
                              <PrintIcon />
                            </Button>
                          </Tooltip>

                          {showComponent("delete") && (
                            <Tooltip title="Delete Srf" placement="top-start">
                              <Button
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Really want to delete srfs?"
                                    )
                                  ) {
                                    submitSiteDelete(row.id);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            colSpan={3}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
          />
          {renderTimeline()}
          <ToastContainer />
        </TableContainer>
      {/* )} */}
    </>
  );
}
