import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

// import { ClassicTable } from "/../utils/components/Styles";
import { BASE_URL } from "./../../global";
// import { insert, view } from "/../components/common/api";
import { integerPropType } from "@mui/utils";
import moment from "moment";

import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';


const axios = require("axios");

export default function PaymentReport() {
const [todaysEnquiryCount, setTodaysEnquiryCount] = React.useState(0)
const [todaysQuotationCount, setTodaysQuotationCount] = React.useState(0)
const [todaysPoAcceptanceCount, setTodaysPoAcceptanceCount] = React.useState(0)
const [todaysCustomInvoiceCount, setTodaysCustomInvoiceCount] = React.useState(0)

const getTodayData = () => {
  let url = BASE_URL;
  let payload = {
    query: `(SELECT 'enquiry' AS table_name, COUNT(*) AS entry_count FROM enquiry WHERE DATE(lastModified) = CURDATE()) UNION ALL (SELECT 'quotation', COUNT(*) FROM quotation WHERE DATE(lastModified) = CURDATE()) UNION ALL (SELECT 'poAcceptance', COUNT(*) FROM poAcceptance WHERE DATE(lastModified) = CURDATE()) UNION ALL (SELECT 'customInvoice', COUNT(*) FROM customInvoice WHERE DATE(lastModified) = CURDATE());`,

  };
  axios
    .post(url + `dynamic`, payload)
    .then((res) => {
      let data = res.data;
      setTodaysEnquiryCount(data?.[0]?.entry_count)
      setTodaysQuotationCount(data?.[1]?.entry_count)
      setTodaysPoAcceptanceCount(data?.[2]?.entry_count)
      setTodaysCustomInvoiceCount(data?.[3]?.entry_count)
    })
    .catch((error) => {
      console.log(error);
    });
};

useEffect(()=>{
  getTodayData()
},[])

return (
    <Paper sx={{ mt: 2, p: 2 }}>
     
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Enquiry </th>
            <th>Quotation</th>
            <th>PO acceptance  </th>
            <th>Invoice  </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{todaysEnquiryCount}</td>
            <td>{todaysQuotationCount}</td>
            <td>{todaysPoAcceptanceCount}</td>
            <td>{todaysCustomInvoiceCount}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}
