import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseIcon from "@mui/icons-material/Close";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Divider,
  Button,
  Grid,
  Autocomplete,
  TextField,
  TableContainer,
} from "@mui/material";
import { ClassicTable } from "./../../utils/components/Styles";
const axios = require("axios");

export default function ViewDispatch() {
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [instruments, setInstruments] = React.useState("");
  const [dispatchMedium, setDispatchMedium] = React.useState("");
  const [dispatchDate, setDispatchDate] = React.useState("");
  const [dispatchData, serDispatchData] = React.useState([]);
  const [instrumentData, setInstrumentsData] = React.useState([]);
  const [instrumentQnt, setInstrumentQnt] = React.useState(0);
  const params = useParams();
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);

  const getDispatchIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `dispatch/${params.id}`)
      .then((res) => {
        setCompanyName(res.data[0].companyName);
        setAddress(res.data[0].address);
        setInstruments(res.data[0].instruments);
        setDispatchMedium(res.data[0].dispatchMedium);
        setDispatchDate(res.data[0].dispatchDate);

        var instrumentQnt = [];
        var instruments = [];
        res.data.map((instrument) => {
          instrumentQnt.push(res.data[0].instrumentIds.split(","));
          instruments.push(res.data[0].instrumentIds.split(","));
        });
        setInstrumentQnt(instrumentQnt[0].length);
        serDispatchData(res.data);

        // axios
        //   .get(url + `instruments/bulk?_ids=${instruments.toString()}`)
        let payload= {
          query: `SELECT srfInst.instrumentId,srfInst.id as srfInstID, instruments.id, instruments.instrumentName FROM srfInstruments AS srfInst JOIN instruments ON srfInst.instrumentId = instruments.id WHERE srfInst.id IN (${instruments.toString()})`
        }
        axios
        .post(url + `dynamic`, payload)
          .then((res) => {
            setInstrumentsData(res.data);
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getDispatchIdList();
  }, []);

  //////-------------------------------------------------------////-----------------------------------------------////

  const fixedColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
    },
    {
      field: "description",
      headerName: "Instruments",
      editable: true,
    },
    {
      field: "ducID",
      headerName: "DUCID",
      editable: true,
    },
    {
      field: "serialNumber",
      headerName: "Serial number",
      editable: true,
    },
    {
      field: "range",
      headerName: "DUC Range",
      editable: true,
    },
    {
      field: "lc",
      headerName: "Least Count",
      editable: true,
    },
    {
      field: "make",
      headerName: "DUC Make",
      editable: true,
    },
    {
      field: "model",
      headerName: "Model",
      editable: true,
    },
    {
      field: "conOfDuc",
      headerName: "Con. Of Duc",
      editable: true,
    },
    {
      field: "calibrationFrequency",
      headerName: "Calibration frequency",
      editable: true,
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
      editable: true,
    },
    {
      field: "calibrationType",
      headerName: "Calibration Type",
      editable: true,
    },
    {
      field: "location",
      headerName: "Location",
      editable: true,
    },
    {
      field: "locationOfInstrument",
      headerName: "Location Of Instrument",
      editable: true,
    },
    {
      field: "calPoint",
      headerName: "Calibration Point",
      editable: true,
    },
    {
      field: "calMethod",
      headerName: "Calibration Method",
      editable: true,
    },
    {
      field: "labCapabilities",
      headerName: "Lab Capabilities",
      editable: true,
    },
  ];

  const errorFn = (errTxt, err) => {
    console.error(errTxt, err);
    return ["err", err];
  };

  const [state, setState] = React.useState({
    srfInstruments: [],
    allInstruments: [],
    clientName: "",
    clientId: "",
    receiptDate: "",
    poNumber: "",
    poDate: "",
    committedDeliveryDate: "",
    serviceReqNumber: "",
    jobNumber: "",
    description: "",
    dcNumber: "",
    dcDate: "",
    entryDate: "",
    address: "",
    readingRows: [],
    fullColumnsLoaded: false,
    multiValueData: {},
    multiValueModalOpen: false,
    unitArray: [],
    copyQt: [],
    readingColumns: [fixedColumns],
    oldData: {},
  });

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    return axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => [[...[""].concat(res.data)]])
      .catch((err) =>
        errorFn("Something Went Wrong at (getUnitMasterArray) : ", err)
      );
  };

  const getSrfsList = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          `xjoin?_join=srf.srfs,_j,client.clients&_on1=(srf.clientId,eq,client.id)&_fields=srf.serviceReqNumber,srf.jobNumber,srf.poNumber,srf.poDate,srf.receiptDate,srf.committedDeliveryDate,srf.dcNumber,srf.dcDate,srf.entryDate,srf.description,client.address,client.id,client.companyName&_where=(srf.id,eq, ${params.srfId})`
      )
      .then((res) => [res.data])
      .catch((err) => errorFn("Something Went Wrong at (getSrfsList) : ", err));
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    return axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.srfId})`)
      .then((res) => {
        // setSrfInstruments(res.data);
        return [res.data];
      })
      .catch((err) =>
        errorFn("Something Went Wrong at (srfInstrumentsList) : ", err)
      );
  };

  const getExtraColumns = () => {
    // Terms and Condition
    let url = BASE_URL;
    return axios
      .get(
        url +
          "settings?_where=(keyName,eq,srfInstrumentsAdditionalColumns)&_fields=value"
      )
      .then((res) => {
        let tmp = [];
        if (res.data.length > 0) {
          // let new
          tmp = [
            state.readingColumns[0].concat(
              res.data[0].value.split(",").map((col) => {
                return {
                  field: col,
                  headerName: col,
                  editable: true,
                };
              })
            ),
          ];

          setState({ ...state, readingColumns: [tmp] });
        }

        return [tmp];
      })
      .catch((err) =>
        errorFn("Something Went Wrong at (getExtraColumns) : ", err)
      );
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    return axios
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => [res.data])
      .catch((err) =>
        errorFn("Something Went Wrong at (instrumentsList) : ", err)
      );
  };

  const getData = () => {
    Promise.all([
      getUnitMasterArray(),
      getSrfsList(),
      srfInstrumentsList(),
      getExtraColumns(),
      instrumentsList(),
    ])
      .then((res) => {
        let st = { ...state };
        if (res[0][0] !== "err") st.unitArray = res[0][0];
        if (res[1][0] !== "err") {
          st.serviceReqNumber = res[1][0][0].srf_serviceReqNumber;
          st.jobNumber = res[1][0][0].srf_jobNumber;
          st.poNumber = res[1][0][0].srf_poNumber;
          st.poDate = res[1][0][0].srf_poDate;
          st.receiptDate = res[1][0][0].srf_receiptDate;
          st.dcNumber = res[1][0][0].srf_dcNumber;
          st.dcDate = res[1][0][0].srf_dcDate;
          st.entryDate = res[1][0][0].srf_entryDate;
          st.description = res[1][0][0].srf_description;
          st.committedDeliveryDate = res[1][0][0].srf_committedDeliveryDate;
          st.address = res[1][0][0].client_address;
          st.clientName = res[1][0][0].client_companyName;
          st.clientId = res[1][0][0].client_id;
        }
        if (res[2][0] !== "err") st.srfInstruments = res[2][0];
        if (res[3][0] !== "err") {
          st.readingColumns = res[3][0];
          st.fullColumnsLoaded = true;
        }
        if (res[4][0] !== "err") st.allInstruments = res[4][0];

        let rows = [];
        for (let i = 0; i < res[2][0].length; i++) {
          let extra = res[2][0][i].extraColumns
            ? JSON.parse(res[2][0][i].extraColumns)
            : {};
          rows.push(
            [
              res[2][0][i].id,
              res[2][0][i].instrumentId,
              res[2][0][i].DUCID,
              res[2][0][i].serialNumber,
              res[2][0][i].ranges,
              res[2][0][i].lc,
              res[2][0][i].make,
              res[2][0][i].model,
              res[2][0][i].ConOfDuc,
              res[2][0][i].calFrequency,
              res[2][0][i].accuracy,
              res[2][0][i].calibrationType,
              res[2][0][i].location,
              res[2][0][i].locationOfInstrument,
              res[2][0][i].calPoint,
              res[2][0][i].calMethod,
              res[2][0][i].labCapabilities,
            ].concat(
              res[2][0][i].extraColumns
                ? Array(res[3][0][0].length - fixedColumns.length)
                    .fill("")
                    .map((e, j) => {
                      return extra[res[3][0][0][fixedColumns.length + j].field]
                        ? extra[res[3][0][0][fixedColumns.length + j].field]
                        : e;
                    })
                : Array(res[3][0][0].length - fixedColumns.length).fill("")
            )
          );
        }

        for (let i = 0; i < rows.length; i++) {
          for (let j = 0; j < res[4][0].length; j++) {
            if (res[4][0][j].id === rows[i][1]) {
              rows[i][1] = rows[i][1] + ", " + res[4][0][j].instrumentName;
              // delete rows[i][0];
            }
          }
        }
        let rRs = {};
        rows.map((r) => {
          rRs[r[0]] = [...r];
        });
        setState({
          ...st,
          readingRows: [...rows],
          copyQt: Array(rows.length).fill(1),
          oldData: {
            ...st,
            readingRows: { ...rRs },
            copyQt: Array(rows.length).fill(1),
          },
        });
      })
      .catch((err) => errorFn("Something Went Wrong at (getDta) : ", err));
  };
  const textStyle = {
    fontWeight: "bold",
    display: "inline-block",
    minWidth: "120px",
  };
  const tableCellStyle = {
    // border: "1px solid black",
    borderRight:"none",
    padding: "8px",
    fontSize: "0.7rem"
  };

  const tableHeadStyle = {
    backgroundColor: "#1976d2",
    color: "white",
    fontWeight: "bold",
    ...tableCellStyle,
  };

  const filterData = instrumentData?.flatMap((e) =>
    state?.readingRows?.filter((ele) => ele[0] == e.srfInstID)
  );

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:130px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async () => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
        <td style="vertical-align: top;height:110px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;">
                <b style="font-size:20px;vertical-align: top;">Signature & Stamp of Customer (Recipient)</b>
            </td>
            <td style="vertical-align: top;height:110px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;">
                <b style="font-size:20px;vertical-align: top;">Lab Stamp & Signature</b>
            </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [92, 1, 105, 1],
        filename: `dispatch_${params.srfId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let image = await generateDynamicHeader(i,totalPages);
      pdf.addImage(image, 0, 50, 595, 55);

      if (i === totalPages) {
        image = await generateDynamicFooter();
        pdf.addImage(image, 0, 730, 595, 90);
      }

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 807, 595, 40);
      }
    }

    pdf.save();
  };

  useEffect(() => {
    getData();
  }, []);

  //////-----------------------------------------------------//////////////////////
  return (
    <div>
      <div style={{padding:"5px 40px"}}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      </div>
      <div ref={printComponentRef}>
      <div style={{ width: "100%" }} id="header-section"></div>
        <Paper
          style={{
            padding: "5px 40px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
        <Grid container spacing={2}>
          <Grid md={12} className="font-link" align="center">
            <h5 style={{ color: "#000066", margin: "20px 0px" }}>
              <b> DELIVERY CHALLAN</b>
            </h5>
          </Grid>
        </Grid>
          
          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
              marginBottom: "30px",
            }}
          />
          <TableContainer>
          <Table sx={{  minWidth: 650, margin:"auto"}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                  Customer's Name
                </TableCell>
                <TableCell align="left" style={{ width: "28%",borderRight: "none",fontSize: "0.7rem" }}>
                {state?.clientName}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 Challan No
                </TableCell>
                <TableCell align="left" style={{ width: "28%",fontSize: "0.7rem" }}>
                {state?.dcNumber || ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 Address
                </TableCell>
                <TableCell align="left" style={{ width: "28%",borderRight: "none",fontSize: "0.7rem" }}>
                {state?.address}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 Challan Date 
                </TableCell>
                <TableCell align="left" style={{ width: "28%",fontSize: "0.7rem"}}>
                {moment(state?.dcDate).format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 SRF
                </TableCell>
                <TableCell align="left" style={{ width: "28%",borderRight: "none",fontSize: "0.7rem" }}>
                {state?.serviceReqNumber}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 Customer's DC No 
                </TableCell>
                <TableCell align="left" style={{ width: "28%",fontSize: "0.7rem" }}>
                  {/* {complainData?.[0]?.customerId} */}
                </TableCell>
              </TableRow> 
              <TableRow>
              <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderBottom: "1px solid black",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                </TableCell>
                <TableCell align="left" style={{ width: "28%",borderBottom: "1px solid black",borderRight: "none",fontSize: "0.7rem"}}>
                  
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                    borderBottom: "1px solid black",
                    borderRight: "none",
                    fontSize: "0.7rem"
                  }}
                >
                 Customer's DC Date 
                </TableCell>
                <TableCell align="left" style={{ width: "28%",borderBottom: "1px solid black",fontSize: "0.7rem"}}>
                  
                </TableCell>
              
              </TableRow>      
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>

        <Paper sx={{ padding: "40px" }}>
          <Table sx={{ minWidth: 650 }}>
    <TableHead>
      <TableRow>
        <TableCell style={tableHeadStyle}>Sr. No.</TableCell>
        <TableCell style={tableHeadStyle}>Description</TableCell>
        <TableCell style={tableHeadStyle}>Size / Range</TableCell>
        <TableCell style={tableHeadStyle}>Serial number</TableCell>
        <TableCell style={{ ...tableHeadStyle, borderRight: "1px solid black"  }}>DUCID</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filterData?.map((row, index) => (
        <TableRow key={index}>
          <TableCell style={tableCellStyle}>{index + 1}</TableCell>
          <TableCell style={tableCellStyle}>{row[1].split(",")[1]}</TableCell>
          <TableCell style={tableCellStyle}>{row[4].replaceAll("#", "").replaceAll("|", ", ")}</TableCell>
          <TableCell style={tableCellStyle}>{row[3]}</TableCell>
          <TableCell style={{ ...tableCellStyle, borderRight: "1px solid black" }}>{row[2]}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
        </Paper>
      </div>
    </div>
  );
}
