// Use This for testing
const URL = "lims.bhavidtech.com";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "Kotak Mahindra Bank Limited",
  "Bank A/c. No.": "8912321879",
  "IFSC Code": "KKBK0000831",
  "MICR Code": "360485002",
  "MSME Registration Number":"UDYAM-GJ-20-0055646",
  "PAN No":"AAHCB7962L",
  "MSME Type":"Micro",
  "Branch":"0831 / RAJKOT-DR. YAGNIK ROAD",
  "Account Type":"Current Account"
};
export const GST_NUMBER = "24AAHCB7962L1Z8";

export const DISABLED_MODULE =["Datasheets","Certificates","Work Report","Scheduler","Summary Report","History Card","Expenses","Master In/Out","Customer Complaints","Customer Feedback","Audit","Document Upload","Purchase"]; 
