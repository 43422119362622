import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TableContainer,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import IconButton from "@mui/material/IconButton";
const axios = require("axios");

export default function UpdatreClient(props) {
  const [companyName, setCompanyNanme] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([["", "", ""]]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");
  const [signature, setSignature] = React.useState(null);
  const [branches, setBranches] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(null);
  const [addresses, setAddresses] = React.useState([]);

  const params = useParams();

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },
      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
      },
      {
        field: "email",
        headerName: "email Id",
        editable: true,
      },
    ],
  ];

  const getBranches = (setBranch) => {
    axios.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const initiateChData = () => {
    try {
      let rows = [];
      var contactDetailsArray = [];
      if (contact != "") {
        contactDetailsArray = JSON.parse(contact);
      }
      for (let i = 0; i < contactDetailsArray.length; i++) {
        rows.push([
          contactDetailsArray[i].contact,
          contactDetailsArray[i].contactPersonName,
          contactDetailsArray[i].emailId,
        ]);
      }
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `clients/${params.id}`)
      .then((res) => {
        setCompanyNanme(res.data[0].companyName);
        setContact(res.data[0].contact);
        setAddress(res.data[0].address);
        let address = res.data[0].address;
        setAddresses(address?.split("||"));
        // setShippingAddress(res.data[0].shippingAddress);
        setGstNumber(res.data[0].gstNumber);
        setState(res.data[0].state);
        setEmail(res.data[0].email);
        setSignature(res.data[0].signature);
        getBranches((inpt) => {
          let tmp = {};
          inpt.map((e) => (tmp[e.id] = e));
          res.data[0].branch
            ? setSelectedBranch(tmp[res.data[0].branch] || null)
            : setSelectedBranch(null);
        });
        let rows = [];
        var contactDetailsArray = [];
        if (res.data[0].contact != "" && res.data[0].contact != null) {
          contactDetailsArray = JSON.parse(res.data[0].contact);
        }
        for (let i = 0; i < contactDetailsArray.length; i++) {
          rows.push([
            contactDetailsArray[i].contactPersonName,
            contactDetailsArray[i].contact,
            contactDetailsArray[i].emailId,
          ]);
        }
        setReadingRows(rows);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const onSubmit = async () => {
    let rows = readingRows.map((row) => ({
      contactPersonName: row[0] || "",
      contact: row[1] || "",
      emailId: row[2] || "",
    }));
    var checkedObj = {
      forOrganization: {
        calibratedBy: calibratedByChecked.toString(),
        checkedBy: checkedByChecked.toString(),
        approvedBy: approvedByChecked.toString(),
      },
      forClient: {
        reviewedBy: reviewedByChecked.toString(),
        signedBy: signedByChecked.toString(),
      },
    };
    var jsonData = {
      companyName: companyName,
      contact: rows.length ? JSON.stringify(rows) : null,
      address: address,
      shippingAddress: shippingAddress,
      gstNumber: gstNumber,
      state: state,
      email: email,
      signature: JSON.stringify(checkedObj),
      branch: selectedBranch ? `${selectedBranch?.id}` : null,
    };
    let url = BASE_URL;

    let ret = params.id
      ? axios.patch(url + `clients/${params.id}`, jsonData)
      : axios.post(url + `clients`, jsonData);
    ret
      .then((res) => {
        toast.success(params.id ? "clients updated!" : "client Created!");
        props.setLoader(false);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  useEffect(() => {
    if (params.id) getClientsList();
    initiateChData();
    if (!params.id) getBranches(() => {});
  }, []);

  const MultiAddressInput = () => {
    const handleAddAddress = () => {
      setAddresses([...addresses, ""]);
    };

    const handleDeleteAddress = (index) => {
      const updatedAddresses = [...addresses];
      updatedAddresses.splice(index, 1);
      setAddresses(updatedAddresses);
    };

    const handleAddressChange = (index, value) => {
      const updatedAddresses = [...addresses];
      updatedAddresses[index] = value;
      setAddresses(updatedAddresses);
    };

    useEffect(() => {
      const nonEmptyAddresses = addresses.filter(
        (address) => address.trim() !== ""
      );
      setAddress(nonEmptyAddresses.join(" || "));
      // setConcatenatedAddresses(nonEmptyAddresses.join(' || '));
    }, [addresses]);

    return (
      <div>
        <Toolbar style={{ padding: "0px" }} sx={{ mt: -1.5 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={handleAddAddress}
          >
            Add Address
          </Button>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "rgb(245 245 245)" }}>
                <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell style={{ fontWeight: "bold",width:"80px" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.map((address, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      label={`Address ${index + 1}`}
                      value={address}
                      onChange={(e) =>
                        handleAddressChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteAddress(index)}>
                      <DeleteIcon style={{ color: "red", cursor: "pointer" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Client
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyNanme(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>{" "} */}
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            id="branch"
            size="small"
            options={branches || []}
            defaultValue={undefined}
            value={selectedBranch || null}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.branchName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Branch"
                placeholder="Select Branch"
              />
            )}
            onChange={(event, value) => setSelectedBranch(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="GST Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="State *"
            size="small"
            fullWidth
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          {MultiAddressInput()}
        </Grid>
      </Grid>
      <br />

      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      </div>

      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
