import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import EditCertificate from "./editCertificate";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import html2pdf from "html2pdf.js";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { toPng } from "html-to-image";
import moment from "moment";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";

export default function MultipleCertificate() {
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  let { certificateIds } = useParams();
  const certificateIdsArray = certificateIds.split(",").map(Number);

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div style="padding:30px;">
      <table style="width:100%;height: 30px;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Url No </b> <br />
            <span style="font-size:19px;">${ulrNumber}</span>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Certificate No</b>
            <br>
            <span style="font-size:19px;">${certificateNumber}</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Page Number</b>
            <br>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <span style="font-size:19px;">${calibratedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
                <br>
                <span style="font-size:19px;">${calibratedby}</span>
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CHCECKED AND APPROVED BY <br> Electronically signed by </b> <br>
                <span style="font-size:19px;">${approvedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>`
                : `
            <td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:17px;vertical-align: top;">CHCECKED AND APPROVED BY </b>
                <br>
                <span style="font-size:19px;">${approvedby}</span>
                `
            }
            </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [140, 1, 165, 1],
        filename: `AllCertificate.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let ulrNumber = "ABC123456";
      let certificateNumber = "361";
      let calibratedby = "BFI";
      let approvedby = "BFI123";
      let calibrationDate = "2023-09-21T03:03:29.000Z";

      let image = await generateDynamicHeader(
        ulrNumber,
        certificateNumber,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 70, 595, 80);

      image = await generateDynamicFooter(
        calibratedby,
        approvedby,
        calibrationDate
      );
      pdf.addImage(image, 0, 675, 595, 100);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 772, 595, 70);
      }
    }

    pdf.save();
    countOfCertificatesAnalytics(certificateIdsArray)
  };

  return (
    <div>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <div ref={printComponentRef}>
        {certificateIdsArray.map((e, index) => (
          // <div style={{ pageBreakAfter: "always" }}>
          <EditCertificate
            key={index}
            certificateIds={certificateIdsArray[index]}
          />
          // </div>
        ))}
      </div>
    </div>
  );
}
