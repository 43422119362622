import React from "react";
import axios from "axios";
import { BASE_URL } from "../../global";

const TestSRF = () => {
  async function updateSRFAnalytics1(id, instrumentId) {
    try {
      let progressPercent = 0;

      const url = `${BASE_URL}dynamic`;

      const countQueryPayload = {
        query: `SELECT COUNT(*) FROM srfAnalytics WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
      };

      const response = await axios.post(url, countQueryPayload);
      const count = response.data?.[0]?.["COUNT(*)"];
      // console.log("ID and count",id,count)

      if (count == 0) {
        const insertQueryPayload = {
          query: `INSERT INTO srfAnalytics (srfId, srfInstrumentId) VALUES (${id}, ${instrumentId})`,
        };
        await axios.post(url, insertQueryPayload);
      }

      progressPercent += 25;

      const findQueryPayload = {
        query: `SELECT serviceReqNumber FROM srfs WHERE id = ${id}`,
      };
      const response1 = await axios.post(url, findQueryPayload);
      const serviceReqNumber = response1.data?.[0]?.serviceReqNumber;

      const response2Payload = {
        query: `SELECT totalReadings FROM datasheets WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
      };
      const response2 = await axios.post(url, response2Payload);
      const totalReadings = response2.data?.[0]?.totalReadings;
      if (totalReadings > 0) {
        progressPercent += 25;
      }

      const response3Payload = {
        query: `SELECT approvedby FROM certificates WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
      };
      const response3 = await axios.post(url, response3Payload);
      const approvedby = response3.data?.[0]?.approvedby;
      if (approvedby) {
        progressPercent += 25;
      }

      const response4Payload = {
        query: `SELECT instrumentIds FROM dispatch WHERE srfsId = ${id}`,
      };
      const response4 = await axios.post(url, response4Payload);
      const instrumentIds = response4.data?.[0]?.instrumentIds;
      const ArrayInstrumentIds = instrumentIds ? instrumentIds.split(",") : [];
      if (ArrayInstrumentIds.includes(`${instrumentId}`)) {
        progressPercent += 25;
      }

      const UpdateDataPayload = {
        query: `UPDATE srfAnalytics SET progressPercent = ${progressPercent} WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
      };
      const UpdateData = await axios.post(url, UpdateDataPayload);

      console.log("progressPercent", progressPercent);
    } catch (error) {
      console.error("Error creating createSRFAnalytics", error);
    }
  }

  updateSRFAnalytics1(236, 114);

  return <div>TestSRF</div>;
};

export default TestSRF;
