import React, { useEffect, useState } from 'react';
import { BASE_URL } from "./../../global";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Button } from '@mui/material';

const TodaysEmailSend = () => {
  const [counts, setCounts] = useState({
    dispatchCount: 0,
    enquiryCount: 0,
    invoicesCount: 0,
    quotationCount: 0,
    srfCount: 0,
    datasheetsCount: 0,
    certificatesCount: 0,
  });

  const fetchTodayCount = async (table, dateColumn) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) = CURDATE()`;
      const response = await axios.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchDataCounts = async () => {
    const dispatchCount = await fetchTodayCount('dispatch', 'dispatchDate');
    const enquiryCount = await fetchTodayCount('enquiry', 'date');
    const invoicesCount = await fetchTodayCount('invoices', 'lastModified');
    const quotationCount = await fetchTodayCount('quotation', 'date');
    const srfCount = await fetchTodayCount('srfs', 'lastModified');
    const datasheetsCount = await fetchTodayCount('datasheets', 'lastModified');
    const certificatesCount = await fetchTodayCount('certificates', 'calibrationDate');

    setCounts({
      dispatchCount,
      enquiryCount,
      invoicesCount,
      quotationCount,
      srfCount,
      datasheetsCount,
      certificatesCount,
    });
  };

  useEffect(() => {
    fetchDataCounts();
  }, []);

  const sendEmail = async (userEmail, clientName, subject, description) => {
    try {
      await axios.post("http://localhost:5000/api/email", {
        userEmail,
        clientName,
        subject,
        description,
      });
      toast("Email sent successfully !");
    } catch (error) {
      console.error("Error sending email", error);
      toast.error("Something went wrong. Failed to send email.");
    }
  };

  const emailData = [
    {
      userEmail: "shubhamgohad221997@gmail.com",
      clientName: "shubham1",
      subject: "Today's Data1",
    },
    {
      userEmail: "adityamuthal.2208@gmail.com",
      clientName: "aditya",
      subject: "Today's Data2",
    },
    {
      userEmail: "shubhamgohad22@gmail.com",
      clientName: "shubham1",
      subject: "Today's Data3",
    },
  ];

  const handleSendEmails = () => {
    const description = `
      <p>I trust this email finds you well. As we wrap up another productive day, I'm pleased to provide you with a snapshot of today's activities:</p>
      <p><br></p>
      <p>Enquiry Added: ${counts.enquiryCount},&nbsp;</p>
      <p>Enquiry Converted:--,</p>
      <p>Invoice Prepared:${counts.invoicesCount},</p>
      <p>Quotation prepared: ${counts.quotationCount},</p>
      <p>Inward:${counts.srfCount},</p>
      <p>Datasheet: ${counts.datasheetsCount},</p>
      <p>Dispatch: ${counts.dispatchCount},</p>
      <p><br></p>
      <p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p>
      <p><br></p>
      <p>Kindly confirm the receipt of the mail While sending the Instrument send all accessories(cables, power cord etc) along with the instruments for calibration</p>
      <p><br></p>
      <p>Thanks &amp; Regards,</p>
      <p><br></p>
      <p>Er. Pravin Kashid</p>
      <p>(Founder)</p>
      <p>[LAB City]</p>
      <p><br></p>
      <p>&nbsp;Regd. Office: 129, Vindya Complex, Sector-11,</p>
      <p>CBD Belapur Navi Mumbai, Maharashtra.</p>
      <p>Contact: 9769847865</p>
      <p>Website: <a href="https://bfiinfotech.co.in/" rel="noopener noreferrer" target="_blank">bfiinfotech.co.in</a></p>
      <p>&nbsp;[LAB City Is The Product Of BFI]</p>
    `;

    emailData.forEach(({ userEmail, clientName, subject }) => {
      sendEmail(userEmail, clientName, subject, description);
    });
  };

  return (
    <div>
      <div>TodaysEmailSend</div>
      <div>
        <Button onClick={handleSendEmails}>Send Email</Button>
      </div>
    </div>
  );
}

export default TodaysEmailSend;
