import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Table } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { showComponent } from "../../helper/helpers";
import "../../helper/helper.css";
import { userTypes } from "./editUserMaster";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "type", label: "Type", minWidth: 40 },
  { id: "branches", label: "Branches", minWidth: 40 },
  { id: "modules", label: "Modules", minWidth: 60 },
];

function createData(id, userName, password, type, branches, modules) {
  return {
    id,
    userName,
    password,
    type: userTypes.filter((e) => e.id == type)?.[0].label,
    branches,
    modules,
  };
}

export default function UserList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quotationList, setQuotationList] = React.useState([]);
  const [userData, setUserData] = React.useState();
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const userType = localStorage.getItem("type");

  const [title, setTitle] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    props.setLoader(true);
    console.log(id);
    let url = BASE_URL;
    axios
      .delete(url + `users/${id}`)
      .then((res) => {
        toast("User is deleted!");
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "User Name": "username",
        Type: "type",
        "Company Name": "companyName",
      }[searchBy];
      whereConditions = `(${colName} like %${searchKey}%)`;
    }

    if (to != null && from != null) {
      if (whereConditions !== "") {
        whereConditions += " and ";
      }

      whereConditions += `(date between ${moment(from).format(
        "YYYY-MM-DD"
      )} and ${moment(to).format("YYYY-MM-DD")})`;
    }

    if (rowsPerPage != -1) {
      pagination = ` limit ${rowsPerPage} offset ${Number(page)} `;
    }

    if (whereConditions !== "") {
      whereConditions += " and ";
    }
    whereConditions += "type != 3";

    whereConditions = whereConditions !== "" ? ` and ${whereConditions}` : "";

    let data = {
      query: `select id, userName, password, type, modules, branch from users where status = 1 ${whereConditions} order by id DESC ${pagination}`,
    };
    if (count)
      data.query = `select count(id) no_of_rows from users where status = 1 ${whereConditions} order by id DESC ${pagination}`;
    return data;
  };

  function getClientList() {
    let data = getQuery();
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        axios.get(BASE_URL + "branch?_where=status=1").then((res2) => {
          let tmp = {};
          res2.data.map((b) => (tmp[b.id] = b));
          let users = res.data;
          var rowData = [];
          for (let i = 0; i < users.length; i++) {
            rowData.push(
              createData(
                users[i].id,
                users[i].userName,
                users[i].password,
                users[i].type,
                (users[i].branch?.split(",") || [])
                  ?.map((e) => tmp[e]?.branchName)
                  ?.join(","),
                users[i].modules
              )
            );
          }
          setUserData(rowData);
        });
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  console.log("User Data : ", userData);
  console.log(
    "searchBy : < ",
    searchBy,
    " > - searchKey : < ",
    searchKey,
    " >"
  );

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        {showComponent("add") && (
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/master/users/addUser"
            onClick={() => {
              setTitle("Master/ add new user");
            }}
          >
            <b>create user</b>
          </Button>
        )}
      </Toolbar>
      <br />
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "username", label: "User Name" },
            { key: "type", label: "Type" },
            { key: "companyName", label: "Company Name" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <br />

      <div style={{ margin: "10px" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
          width="100%"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData &&
              userData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {showComponent("show") && (
                          <Tooltip title="Edit User" placement="top-start">
                            <Button
                              component={Link}
                              to={`/master/users/editUser/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {showComponent("delete") && (
                          <Tooltip
                            title="Delete User"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to delete User?"
                                  )
                                ) {
                                  submitSiteDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon style={{ color: "#dc3545" }} />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </div>
    </TableContainer>
  );
}
