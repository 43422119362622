import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";

import axios from "axios";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { Button, TableContainer, Toolbar, TextField, Autocomplete } from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tables from "../../../utils/components/tables";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyName",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "DUCID",
    label: "DUCID",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "model",
    label: "Model",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ranges",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "lc",
    label: "LC",
    align: "left",
    minWidth: 40,
  },
  {
    id: "locationOfInstrument",
    label: "Location Of Instrument",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calFrequency",
    label: "Cal Frequency",
    align: "left",
    minWidth: 40,
  },
];

const DucMasterList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "	Client Name": "companyName",
        "Instrument Name": "instrumentName",
        "DUCID": "DUCID",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }


    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT ducMaster.*, clients.companyName, instruments.instrumentName,REPLACE(REPLACE(REPLACE(ducMaster.ranges, '#', ''), '||', ','), '|', ' to ') AS ranges, REPLACE(REPLACE(REPLACE(ducMaster.lc, '#', ''), '||', ','), '|', ' to ') AS lc, REPLACE(REPLACE(REPLACE(ducMaster.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM ducMaster JOIN clients ON ducMaster.clientId = clients.id JOIN instruments ON ducMaster.instrumentId = instruments.id ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM ducMaster JOIN clients ON ducMaster.clientId = clients.id JOIN instruments ON ducMaster.instrumentId = instruments.id  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    getTotalRows();
    let data = getQuery();

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data)
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `ducMaster/${id}`)
      .then((res) => {
        toast("ducMaster is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    fetchFinalData();
  }, []);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit DUC ",
      link: true,
      linkTo: (id) => `/master/editDucMaster/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete DUC ",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (id) => handleDelete(id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/master/editDucMaster"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>create duc Master</b>
            </Button>
          )}
        </Toolbar>
        <br />
        <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={
              _userType != 3
                ? [
                    { key: "companyName", label: "	Client Name" },
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "DUCID", label: "DUCID" },
                  ]
                : [{ key: "companyName", label: "	Client Name" }]
            }

            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  )
}

export default DucMasterList