import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  createFilterOptions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
const axios = require("axios");
const filter = createFilterOptions();

export default function CreateUncertinity(props) {
  const [srfObject, createSrfObject] = useState({});
  const [sourceConfig, setSourceConfig] = useState({ default: "" });
  const [selected, setSelected] = useState("default");
  const [showCondition, setShowCondition] = useState({ default: "" });
  const [selectedCondition, setSelectedCondition] = useState("default");
  const [sourceConfigSensitivity, setSourceConfigSensitivity] = useState({ default: "" });
  const [selectedSensitivity, setSelectedSensitivity] = useState("default");

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    if (srfObject.distribution && srfObject.distribution.split("")[0] == "√") {
      srfObject.formula = Math.sqrt(
        parseInt(srfObject.distribution.trim().split("")[1])
      );
    } else if (
      srfObject.distribution &&
      srfObject.distribution.split("")[0] == "/"
    ) {
      srfObject.formula = srfObject.distribution.trim().split("")[1];
    }

    let row = {
      name: srfObject.name,
      distribution: srfObject.distribution.trim(),
      formula: srfObject.formula,
      sensitives:JSON.stringify(sourceConfigSensitivity),
      sourceconfig: JSON.stringify(sourceConfig),
      showcondition: JSON.stringify(showCondition),
    };

    let url = BASE_URL;
    axios
      .post(url + "uncertainty", row)
      .then((res) => {
        props.setLoader(false);
        toast("Uncertainty created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Uncertainty
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("name", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Distribution *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("distribution", e.target.value);
            }}
          />
        </Grid>{" "}

        <Grid item xs={6} >
        <Typography variant="h6" component="h6" style={{ float: "left", marginBottom:"15px" }}>
        Sensitivity Coefficient
      </Typography>
          <Autocomplete
            value={selectedSensitivity}
            onChange={(event, newValue) => {
              if (newValue === null || newValue.inputValue === null) return;
              if (typeof newValue === "string") {
                setSelectedSensitivity(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedSensitivity(newValue.inputValue);
              } else {
                setSelectedSensitivity(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={Object.keys(
              sourceConfigSensitivity || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set condition " />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={6} marginTop="48px">
          <TextField
            id="outlined-basic"
            label="Formula  *"
            size="small"
            value={sourceConfigSensitivity[selectedSensitivity] || ""}
            fullWidth
            variant="outlined"
            onInputChange={(event, newInputValue) => {
              let tmp = { ...sourceConfigSensitivity };
              tmp[selectedSensitivity] = newInputValue;
              setSourceConfigSensitivity(tmp);
            }}
            onChange={(e) => {
              let tmp = { ...sourceConfigSensitivity };
              tmp[selectedSensitivity] = e.target.value;
              setSourceConfigSensitivity(tmp);
            }}
          />
        </Grid>
        <Grid item xs={6}>
        <Typography variant="h6" component="h6" style={{ float: "left", marginBottom:"15px" }}>
        Uncertainty Factors
      </Typography>
          <Autocomplete
            value={selected}
            onChange={(event, newValue) => {
              if (newValue === null || newValue.inputValue === null) return;
              if (typeof newValue === "string") {
                setSelected(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelected(newValue.inputValue);
              } else {
                setSelected(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={Object.keys(
              sourceConfig || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set condition " />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={6} marginTop="48px">
          <TextField
            id="outlined-basic"
            label="Formula  *"
            size="small"
            value={sourceConfig[selected] || ""}
            fullWidth
            variant="outlined"
            onInputChange={(event, newInputValue) => {
              let tmp = { ...sourceConfig };
              tmp[selected] = newInputValue;
              setSourceConfig(tmp);
            }}
            onChange={(e) => {
              let tmp = { ...sourceConfig };
              tmp[selected] = e.target.value;
              setSourceConfig(tmp);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={selectedCondition}
            onChange={(event, newValue) => {
              if (newValue === null || newValue.inputValue === null) return;
              if (typeof newValue === "string") {
                setSelectedCondition(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedCondition(newValue.inputValue);
              } else {
                setSelectedCondition(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={Object.keys(
              showCondition || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>{option}</div>
                <button
                  style={{ color: "#888" }}
                  onClick={(e) => {
                    let tmp = { ...showCondition };
                    delete tmp[props["key"]];
                    setShowCondition({ ...tmp });
                    setSelectedCondition("");
                  }}
                >
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="CloseIcon"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  </svg>
                </button>
              </li>
            )}
            // renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set show condition" />
            )}
            size="small"
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Default show Value *"
            size="small"
            value={showCondition[selectedCondition] || ""}
            fullWidth
            variant="outlined"
            onInputChange={(event, newInputValue) => {
              let tmp = { ...showCondition }
              tmp[selected] = newInputValue
              setShowCondition(tmp)
            }}
            onChange={e => {
              let tmp = { ...showCondition }
              tmp[selectedCondition] = e.target.value
              setShowCondition(tmp)
            }}
          />
        </Grid> */}
        <Grid item xs={3} style={{ alignItems: "start" }}>
          <FormControlLabel
            classes={{
              alignItems: "left",
            }}
            value={showCondition[selectedCondition] || ""}
            style={{ display: "flex" }}
            control={
              <Checkbox
                onChange={(e) => {
                  let tmp = { ...showCondition };
                  tmp[selectedCondition] = e.target.checked;
                  setShowCondition(tmp);
                }}
              />
            }
            label="Show Cmc"
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
