import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { BASE_URL } from "./../../global";
import { Autocomplete, Grid, TextField, Toolbar, Tooltip } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "customerName",
    label: "Customer Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  // {
  //     id: "workOrderNo",
  //     label: "Work Order No.",
  //     align: "left",
  //     minWidth: 100,
  // },
  {
    id: "range",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "identificationNo",
    label: "Identification No.",
    minWidth: 40,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "dateOfCalibration",
    label: "Date of Calibration",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 60,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  // {
  //     id: "calProcRefNo",
  //     label: "Cal. Proc. Ref. No.",
  //     minWidth: 160,
  //     align: "left",
  //     format: (value) => value.toFixed(2),
  // },
];

function createData(
  id,
  customerName,
  workOrderNo,
  instrument,
  identificationNo,
  dateOfCalibration,
  calProcRefNo
) {
  return {
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo,
  };
}

const rows = [
  createData(
    1,
    "Universal lab",
    "BFI/2112/BFI/22/1/129",
    "Digital Caliper",
    55,
    "1/20/2022 12:00:00 AM",
    "Thermometer rer"
  ),
];

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function ClientHistoryCardList() {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [certificate, setCertificate] = useState(null);
  const [instrument, setInstrument] = useState(null);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [instrumentName, setInstrumentName] = useState("");
  const [identificationNo, setIdentificationNo] = useState("");
  const [dateData, setDateData] = React.useState([]);
  const [pushArrays, setPushArray] = React.useState([]);
  const [certificateNumber, setCertificateNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [ulrNumber, setUlrNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);
  const _clientId = localStorage.getItem("clientId");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  // api calls
  function fetchCertificate() {
    // let url =
    //   BASE_URL +
    //   `xjoin?_join=cert.certificates,_j,ins.instruments,_j,cust.clients&_on1=(cert.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cert.clientId)&_fields=cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,replace(replace( cert.ranges , '#',''),'|',' to ') as ranges,cert.DUCID&_where=(cert.status,eq,1)~or(cert.status,eq,3)~or(cert.status,eq,4)`;

    let data = {
      query: `SELECT cert.clientId, cust.companyName, ins.instrumentName, cert.id, cert.status, cert.dcNumber, cert.calibrationDate, cert.nextDueDate, cert.identificationNo, cert.identificationNo, replace(replace( cert.ranges , '#',''),'|',' to ') as ranges, cert.DUCID FROM certificates AS cert LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id LEFT JOIN clients AS cust ON cust.id = cert.clientId WHERE (cert.status = 1 OR cert.status = 3 OR cert.status = 4) AND cert.clientId = ${_clientId}`,
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate(res.data);
        initializeDataTable();
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });
  }

  function fetchInstruments() {
    //   BASE_URL +
    //   `xjoin?_join=cert.certificates,_j,ins.instruments,_j,cust.clients&_on1=(cert.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cert.clientId)&_fields=cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,replace(replace( cert.ranges , '#',''),'|',' to ') as ranges,cert.DUCID&_where=(cert.status,eq,1)~or(cert.status,eq,3)~or(cert.status,eq,4)`;
    let data = {
      query: `SELECT cert.clientId, cust.companyName, ins.instrumentName, cert.id, cert.status, cert.dcNumber, cert.calibrationDate, cert.nextDueDate, cert.identificationNo, cert.identificationNo, replace(replace( cert.ranges , '#',''),'|',' to ') as ranges, cert.DUCID FROM certificates AS cert LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id LEFT JOIN clients AS cust ON cust.id = cert.clientId WHERE (cert.status = 1 OR cert.status = 3 OR cert.status = 4) AND cert.clientId = ${_clientId}`,
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });
  }

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const FilterData = () => {
    var resultProductData = certificate.filter(
      (data) => data.DUCID === identificationNo
    );
    setDateData(resultProductData);
    initializeDataTable(resultProductData);
  };

  useEffect(() => {
    fetchCertificate();
    fetchInstruments();
  }, []);

  const renderQrModal = () => {
    return (
      <Modal
        size="xl"
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ width: "auto", height: "auto" }}
      >
        <Box sx={qrModalStyle}>
          <div className="historyCard" ref={printComponentRef}>
            <div className="header">
              <h2 style={{ color: "red", textAlign: "center" }}>
                Business Finder Infotech
              </h2>
              <Button
                variant="contained"
                style={{ float: "right", marginTop: "-50px" }}
                size="small"
                sx={{ ml: 3 }}
                className="b"
                onClick={handlePrint}
              >
                Print
              </Button>
              <br />
              <p style={{ fontSize: "15px", textAlign: "center" }}>
                Office No: 120, Vindya Commercial complex, Sector-11, Cbd
                Belapur, Navi Mumbai, Maharashtra
              </p>
            </div>
            <hr className="hr" />
            <div className="body">
              <h4 className="text-center p">
                <u>Calibration Details (History Report)</u>
              </h4>
              <div className="row mt-4">
                <div className="col-6 text-start">
                  <p>
                    Date Of Calibration :{" "}
                    {moment(new Date()).format("DD-MM-YYYY")}
                  </p>
                  <p>Certificate Number : {certificateNumber}</p>
                  <p>Ulr Number : {ulrNumber}</p>
                </div>
              </div>
            </div>
            <table className="table " style={{ width: "100%" }}>
              <thead className="thead-light">
                <tr>
                  <th>id</th>
                  <th>Customer Name </th>
                  <th>Instrument Name</th>
                  <th>Range </th>
                  <th>Least Count </th>
                  <th>Identification Number </th>
                  <th>Date of Calibration </th>
                  <th>Due Date </th>
                </tr>
              </thead>
              {dateData.map((row, index) => (
                <tbody>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row.companyName}</td>
                    <td>{row.instrumentName}</td>
                    <td>{row.ranges}</td>
                    <td>1</td>
                    <td>{row.DUCID}</td>
                    <td>{moment(row.calibrationDate).format("DD-MM-YYYY")}</td>
                    <td>{moment(row.nextDueDate).format("DD-MM-YYYY")}</td>
                  </tr>
                </tbody>
              ))}
            </table>
            <p style={{ textAlign: "right", fontSize: "20px" }}>
              For Global Calibration
            </p>
          </div>
        </Box>
      </Modal>
    );
  };
  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={
                instrument != null
                  ? instrument.map(
                      (item) => item.instrumentName + ", " + item.DUCID
                    )
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Instrument Name" />
              )}
              // onChange={(event, value) => setInstrumentName(value)}
              // prints the selected value
              onChange={(event, value) => {
                if (value != null && value.length > 2) {
                  setInstrumentName(value.split(", ")[0]);
                  setIdentificationNo(value.split(", ")[1]);
                } else {
                  setInstrumentName("");
                  setIdentificationNo("");
                }
              }}
              // [JSON.stringify(value)]
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(e) => {
                  // moment(new Date()).format("YYYY-MM-DD");
                  // console.log(newValue);
                  setFromDate(e);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Toolbar style={{ padding: "0px" }} sx={{ mt: 0.5 }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={(e) => {
                FilterData();
              }}
              style={{ marginLeft: "10px", height: "40px" }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={(e) => {
                setQrCodeUrl(`http://localhost:3000/#/certificate/edit/`);
                handleOpen();
              }}
              style={{ marginLeft: "10px", height: "40px" }}
            >
              Print
            </Button>
          </Toolbar>
        </Grid>
        <hr />
        <br />

        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell style={{ fontWeight: "bold" }}>
                View Certificate
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dateData &&
              dateData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // key={index + 1}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.companyName}</TableCell>

                      <TableCell>{row.instrumentName}</TableCell>
                      <TableCell>{row.ranges}</TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>
                        {moment(row.calibrationDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row.nextDueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip
                            title="View Certificate"
                            placement="top-start"
                          >
                            <Button
                              component={Link}
                              to={`/certificate/viewCertificate/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {renderQrModal()}
    </div>
  );
}
