import React, { useState, useEffect, Fragment } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Link } from "react-router-dom";
import moment from "moment";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ViewHeadline from "@mui/icons-material/ViewHeadline";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import QRCode from "react-qr-code";
import { Autocomplete } from "@mui/material";
import { BASE_URL, DOMAIN } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import Global from "../image/sticker.jpeg";
import leftPanel from "../image/leftPanel.jpeg";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import "./certificateList.css";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  boxShadow: 24,
};
const stModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function CertificateList() {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [certificate, setCertificate] = useState([]);
  const [allcertificateIds, setAllCertificateIds] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [enableDisable, setEnableData] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [dueDate, setDueDate] = React.useState("");
  const [certReadings, setCertReadings] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [letterHead, setLetterHead] = React.useState(-1);
  const [electronicSign, setElectronicSign] = React.useState(-1);
  const [approvedby, setApprovedby] = React.useState("");
  const [calibratedby, setCalibratedby] = React.useState("");
  const [certId, setCertId] = React.useState("");
  const _clientId = localStorage.getItem("clientId");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const validCompanies = localStorage.getItem("validClients");
  const [stikerLoader, setStikerLoader] = React.useState(false);

  const columns = (() =>
    userType !== "3"
      ? [
          {
            id: "checkMark",
            label: "Select",
            align: "center",
            minWidth: 10,
          },
        ]
      : [])()
    .concat([
      {
        id: "id",
        label: "Sr. No.",
        align: "left",
        minWidth: 40,
      },
      {
        id: "customerName",
        label: "Company Name",
        align: "left",
        minWidth: 40,
      },
      {
        id: "serviceReqNumber",
        label: "service Request Number",
        align: "left",
        minWidth: 40,
        // bgcolor:
      },
      {
        id: "jobNumber",
        label: "Job Number",
        align: "left",
        minWidth: 40,
      },
      {
        id: "instrumentName",
        label: "Instrument Name",
        align: "left",
        minWidth: 40,
      },
      {
        id: "range",
        label: "Range",
        minWidth: 40,
        align: "left",
      },
      {
        id: "lc",
        label: "Least Count",
        align: "left",
        minWidth: 40,
      },
      {
        id: "DUCID",
        label: "DUC ID",
        minWidth: 40,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "SrNumber",
        label: "Serial Number",
        minWidth: 40,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "dateOfCalibration",
        label: "Date of Calibration",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
      },
      {
        id: "dueDate",
        label: "Due Date",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
      },
    ])
    .concat(
      userType !== "3"
        ? [
            {
              id: "calibratedby",
              label: "Calibrated By",
              minWidth: 40,
              align: "left",
              format: (value) => value.toFixed(2),
            },
          ]
        : []
    );

  var createData = (
    checkMark,
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo,
    lc,
    serviceReqNumber
  ) => {
    let data = {
      id,
      customerName,
      workOrderNo,
      instrument,
      identificationNo,
      dateOfCalibration,
      calProcRefNo,
      lc,
      serviceReqNumber,
    };
    if (userType !== "3") data = data["checkMark"] = checkMark;
    return data;
  };

  const rows = [
    createData(
      2,
      5,
      1,
      "Universal lab",
      "BFI/2112/BFI/22/1/129",
      "Digital Caliper",
      55,
      "1/20/2022 12:00:00 AM",
      "Thermometer rer"
    ),
  ];

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };
  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  const getCal = (id) => {
    setStikerLoader(true);
    axios
      .get(
        BASE_URL +
          `xjoin?_join=cer.certificates,_j,d.datasheets,_j,usr.users&_on1=(cer.id,eq,d.id)&_on2=(d.calibratedby,eq,usr.id)&_fields=cer.approvedby,d.calibratedby,d.revisionNumber,d.calibrationDate,usr.userName&_where=(cer.id,eq,${id})`
      )
      .then((res) => {
        setApprovedby(res.data[0]?.cer_approvedby);
        setCalibratedby(res.data[0]?.usr_userName);
        setRevisionNumber(res.data[0]?.d_revisionNumber);
        setStikerLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "cust.companyName",
        "Instrument Name": "ins.instrumentName",
        "DUC ID": "cert.DUCID",
        "Serial Number": "cert.serialNumber",
        "SRF Number": "cert.serviceReqNumber",
        "Job Number": "cert.jobNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cert.calibrationDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }
    if (whereConditions == "") {
      whereConditions += ` where cert.status != -1`;
    } else {
      whereConditions += ` and cert.status != -1 `;
    }
    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (ds.assignedUser=${userId}`
          : `where (ds.assignedUser=${userId}`;
      whereConditions += ` or cert.createdby=${userId}`;
      whereConditions +=
        validCompanies?.length > 0
          ? whereConditions.length > 0
            ? ` or cust.id in (${validCompanies}))`
            : ` where cust.id in (${validCompanies})`
          : ")";
    }
    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (cert.clientId=${_clientId})`
          : `where (cert.clientId=${_clientId})`;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` cust.branch in (${branch})`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cert.locationOfInstrument, cert.clientId, cert.certificateNumber, cert.lc, cert.ranges, cert.serviceReqNumber, cert.signedBy, cert.reviewedBy, cert.witnessBy, cert.location, (SELECT userName FROM users WHERE users.id = cert.approvedby) AS approvedby, cust.companyName, ins.instrumentName, cert.jobNumber, cert.id, cert.status, cert.dcNumber, cert.serialNumber, cert.calibrationDate, cert.nextDueDate, cert.identificationNo, cert.identificationNo, ds.calibratedby AS calibratedby_id, (SELECT userName FROM users WHERE users.id = ds.calibratedby) AS calibratedby, cert.DUCID, cert.dateOfIssue FROM certificates AS cert LEFT JOIN clients AS cust ON cert.clientId = cust.id LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id LEFT JOIN datasheets AS ds ON cert.id = ds.id  ${whereConditions} order by cert.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows FROM certificates AS cert LEFT JOIN clients AS cust ON cert.clientId = cust.id LEFT JOIN instruments AS ins ON cert.instrumentId = ins.id LEFT JOIN datasheets AS ds ON cert.id = ds.id  ${whereConditions} order by cert.id DESC ${pagination_settings}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    // var data = {
    //   query: userType == '2'
    //     ? `select count(*) as no_of_rows from certificates as cert left join datasheets as ds on ds.id=cert.id where ds.assignedUser=${userId} or cert.createdby=${userId}`
    //     : `select count(*) as no_of_rows from certificates`
    // }
    let data = getQuery(true);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchCertificates() {
    let data = getQuery();

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate([...res.data]);
        let data = res.data;
        const AllIds = data.map((e) => e.id);
        setAllCertificateIds(AllIds);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  function changeCertificateStatus(ids) {
    var splitDataToArray = ids.substring(2).split(",");
    let url = BASE_URL;
    if (ids.split(":")[0] == 3) {
      if (splitDataToArray.length) {
        splitDataToArray.forEach((id) => {
          axios
            .patch(url + `certificates/${id}`, { status: 3 })
            .then((res) => {
              toast("Certificates are approved successfully!");
              setTimeout(refresh, 200);
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
        });
      }
    } else {
      if (splitDataToArray.length) {
        splitDataToArray.forEach((id) => {
          axios
            .patch(url + `certificates/${id}`, { status: 4 })
            .then((res) => {
              toast("Certificates are rejected successfully!");
              setTimeout(refresh, 500);
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
        });
      }
    }
  }

  const search = () => {
    fetchCertificates();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchCertificates();
  }, [rowsPerPage, page]);

  if (enableDisable.length > 0) {
    var result = enableDisable
      .map(function (item) {
        if (item) {
          let [id, closed] = item.split(",");
          return closed === "true" ? id : null;
        }
      })
      .filter(function (item) {
        return item != undefined && item != null;
      });
  }

  const renderQrModal = () => {
    const fullHeader = true;
    const isHeaderAtLeft = true;
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={qrModalStyle} ref={printComponentRef}>
          {stikerLoader ?
          <Box  
          BackdropComponent={Backdrop}
           style={{
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
         >
        <CircularProgress color="success" />
          </Box>
          :
          <Box>            
          {/* <div className='qrModalStyle'> */}
          {fullHeader ? (
            <img
              src={Global}
              style={{ width: "100%", height: "86px", objectFit: "contain" }}
              alt="rq-logo"
            ></img>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: isHeaderAtLeft ? "row" : "row-reverse",
                height: "50px",
                padding: "3px",
              }}
            >
              <div style={{ padding: "0px 10px" }}>
                <img
                  src={Global}
                  style={{
                    height: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                  alt="rq-logo"
                ></img>
              </div>
              <div style={{ flex: "1" }}></div>
            </div>
          )}

          <div style={{ flex: "1", display: "flex" }}>
            {/* <div style={{ width: "11%" }}>
              <img
                src={leftPanel}
                style={{ maxWidth: "100%", height: "100%" }}
                alt="QR-Header"
              ></img>
            </div> */}
            <div style={{ width: "90%", margin: "auto" }}>
              <table
                style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
              >
                <tbody>
                  <tr>
                    <td className="stickerTd">
                      <b>CERTIFICATE NO</b>{" "}
                    </td>
                    <td className="dataDivFromSticker">
                      <b>{`${certificateNumber}${
                        revisionNumber > 0 ? `/AMD-${revisionNumber}` : ""
                      }`}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="stickerTd">
                      <b>INSTRUMENT</b>
                    </td>
                    <td className="dataDivFromSticker">
                      <b>{instrumentName}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="stickerTd">
                      <b>ID NO</b>
                    </td>
                    <td className="dataDivFromSticker">
                      <b>{idNumber}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="stickerTd">
                      <b>LOCATION</b>
                    </td>
                    <td className="dataDivFromSticker">
                      <b>{location}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className=" bottomDiv"
                      style={{ display: "flex", border: "none" }}
                      colSpan={2}
                    >
                      <div
                        style={{
                          width: "25%",
                        }}
                      >
                        <div className="bottomDivChild">
                          <b>CAL DATE</b>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <b>
                            {calDate
                              ? moment(calDate).format("DD-MM-YYYY")
                              : ""}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                        }}
                      >
                        <div className="bottomDivChild">
                          <b>DUE DATE</b>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <b>
                            {dueDate
                              ? moment(dueDate).format("DD-MM-YYYY")
                              : ""}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                        }}
                      >
                        <div className="bottomDivChild">
                          <b>CAL BY</b>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <b>{calibratedby ? calibratedby : ""}</b>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                        }}
                      >
                        <QRCode
                          value={`${DOMAIN}/#/certificate/print/${certId}`}
                          size={60}
                          align="right"
                          style={{ margin: "auto" }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </Box>
        }
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            // justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={
              userType == 3
                ? [
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "duc", label: "DUC ID" },
                    { key: "serialNumber", label: "Serial Number" },
                    { key: "srfNumber", label: "SRF Number" },
                    { key: "jobNumber", label: "Job Number" },
                  ]
                : [
                    { key: "customerName", label: "Company Name" },
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "duc", label: "DUC ID" },
                    { key: "serialNumber", label: "Serial Number" },
                    { key: "srfNumber", label: "SRF Number" },
                    { key: "jobNumber", label: "Job Number" },
                  ]
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "") setSearchKey("");
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"Enter " + searchBy}
            size="small"
            variant="outlined"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            component={Link}
            to={`/certificate/viewMultipleCertificate/${allcertificateIds.toString()}`}
          >
            <PrintIcon />
            &nbsp; Print All
          </Button>
        </div>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          {result && result.length && (
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (result) {
                    if (newInputValue === "With Letter Head") {
                      setLetterHead(true);
                    } else if (newInputValue === "Without Letter Head") {
                      setLetterHead(false);
                    }
                  }
                }}
              />
            </Grid>
          )}
          {result && result.length && (
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (result) {
                    if (newInputValue === "With Electronically Signed") {
                      setElectronicSign(true);
                    } else if (
                      newInputValue === "Without  Electronically Signed"
                    ) {
                      setElectronicSign(false);
                    }
                  }
                }}
              />
            </Grid>
          )}
          {result && result.length && (
            <Grid item xs={3}>
              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  component={Link}
                  to={`/certificate/viewMultipleCertificate/${result.toString()}`}
                >
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          )}
          {result && result.length && (
            <Grid item xs={3}>
              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  component={Link}
                  to={`/certificate/multiqr/${result.toString()}`}
                >
                  <QrCode2Icon />
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
        <hr />
        <br />
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificate &&
              certificate.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {userType !== "3" ? (
                      <TableCell>
                        <Grid item xs={2}>
                          <FormControlLabel
                            classes={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            sx={{
                              gap: 2,
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  updateEnableDisable(e.target.checked, row.id);
                                }}
                              />
                            }
                            // label={matrixData.matrixName}
                          />
                        </Grid>
                      </TableCell>
                    ) : (
                      console.log("not a admin")
                    )}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <Tooltip
                      title={
                        row?.approvedby
                          ? `Approved By ${row.approvedby}`
                          : "Not Approved Yet"
                      }
                      placement="top"
                    >
                      <TableCell
                        style={{
                          backgroundColor: row.signedBy
                            ? "rgb(181 247 204)"
                            : row.reviewedBy
                            ? "rgb(243 146 243)"
                            : row.witnessBy
                            ? "#f59b79"
                            : row.approvedby
                            ? "rgb(160 160 247)"
                            : "defaultColor",
                        }}
                      >
                        {row.serviceReqNumber}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={
                        row.approvedby
                          ? `Approved By ${row.approvedby}`
                          : "Not Approved Yet"
                      }
                      placement="top"
                    >
                      <TableCell
                        style={{
                          backgroundColor: row.signedBy
                            ? "rgb(181 247 204)"
                            : row.reviewedBy
                            ? "rgb(243 146 243)"
                            : row.witnessBy
                            ? "#f59b79"
                            : row.approvedby
                            ? "rgb(160 160 247)"
                            : "defaultColor",
                        }}
                      >
                        {row.jobNumber}
                      </TableCell>
                    </Tooltip>

                    <TableCell>{row.instrumentName}</TableCell>
                    <TableCell>
                      {row.ranges
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>
                      {row.lc
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>
                      {moment(row.calibrationDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(row.nextDueDate).format("DD-MM-YYYY")}
                    </TableCell>
                    {userType !== "3" && (
                      <TableCell>{row.calibratedby}</TableCell>
                    )}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="View Certificate" placement="top-start">
                          <Button
                            component={Link}
                            to={`/certificate/viewCertificate/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        {userType !== "3" ? (
                          <Tooltip
                            title="Scan Certificate"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                setQrCodeUrl(
                                  `${DOMAIN}/#/certificate/viewCertificate/${row.id}`
                                );
                                handleOpen();
                                setInstrumentName(row.instrumentName);
                                setIdNumber(row.DUCID);
                                setLocation(row.locationOfInstrument);
                                setCertificateNumber(row.certificateNumber);
                                setCalDate(row.calibrationDate);
                                setDueDate(row.nextDueDate);
                                getCal(row.id);
                                setCertId(row.id);
                              }}
                            >
                              <QrCode2Icon />
                            </Button>
                          </Tooltip>
                        ) : (
                          console.log("not a admin")
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
      {renderQrModal()}
    </div>
  );
}
