import React, { useState, useEffect } from "react";
import { BASE_URL, SRF_ID_BASE } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generateNumber } from "../../../utils/utils";
import "./../srf.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { showComponent } from "../../helper/helpers";
import { useParams } from "react-router-dom";

import { fetchClientIdList, useSrfDetails, useSettings } from "./fetchHandlers";
import { DUCInwardDetails } from "./DUCInwardDetails";
import { handleUpdateSubmit, handleCreateSubmit } from "./submitHandlers";

const axios = require("axios");

export default function CreateSRF(props) {
  const params = useParams();
  const { setLoader } = props;

  const [readingRows, setReadingRows] = useState([]);
  const [srfInstrumentsToDelete, setSrfInstrumentsToDelete] = useState([]);

  const [clientArray, setClientArray] = useState([]);
  const [latestId, setLatestId] = useState(null);
  const [latestSRFId, setLatestSRFId] = useState(null);

  // constants
  const userType = localStorage.getItem("type");

  // data hooks
  const [srfDetails, updateSrfDetails, fetchSrfDetails] = useSrfDetails({
    id: params?.id,
  });
  const [settings, fetchSettings] = useSettings({});

  // helper functions

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
      branch: clientArray[i].branchName,
    });
  }

  const getNewCertId = () => {
    return axios
      .get(BASE_URL + `certificates?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestId(0);
        else setLatestId(res.data[0].id);
      });
  };

  const getNewSRFId = () => {
    return axios
      .get(BASE_URL + `srfs?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestSRFId(0);
        else setLatestSRFId(res.data[0].id + 1);
      });
  };

  const validateData = () => {
    let isValid = true;

    if (!srfDetails?.clientId) {
      toast.error("Please select a client");
      isValid = false;
    }
    if (!srfDetails?.entryDate) {
      toast.error("Please select an entry date");
      isValid = false;
    }

    if (!readingRows?.length) {
      toast.error("Please select at least one SRF Instrument!");
      isValid = false;
    }

    readingRows.some((row, index) => {
      if (row.instrumentId == null || row.instrumentId == "") {
        toast.error(`Row ${index + 1}: Please select an Instrument`);
        isValid = false;
      }
      if (row.DUCID == null || row.DUCID == "") {
        toast.error(`Row ${index + 1}: Please add DUCID`);
        isValid = false;
      }
      if (row.locationOfInstrument == null || row.locationOfInstrument == "") {
        toast.error(
          `Please add location of Instrument for row ${index + 1}`
        );
        isValid = false;
        return true;
      }
      if (row.ranges == null || row.ranges == "") {
        toast.error(`Row ${index + 1}: Please add DUC Range(s)`);
        isValid = false;
      }
      if (row.lc == null || row.lc == "") {
        toast.error(`Row ${index + 1}: Please add Least Count(s)`);
        isValid = false;
      }
      if (row.make == null || row.make == "") {
        toast.error(`Row ${index + 1}: Please add DUC Make`);
        isValid = false;
      }
      if (row.calFrequency == null || row.calFrequency == "") {
        toast.error(
          `Row ${index + 1}: Please select the Calibration frequency`
        );
        isValid = false;
      }
      if (row.location == null || row.location == "") {
        toast.error(`Row ${index + 1}: Please select the Location`);
        isValid = false;
      }
    });

    return isValid;
  };

  useEffect(() => {
    params?.id > 0 && fetchSrfDetails();

    fetchClientIdList(setClientArray);
    fetchSettings([
      "srfInstrumentsAdditionalColumns",
      "DUCMasterStatus",
      "Certificate Number",
      "serviceReqNumber",
      "srfInstrumentsAdditionalColumns",
      "ULR number",
      "ULR ID",
    ]);
    getNewCertId();
    getNewSRFId();
  }, []);

  useEffect(() => {
    let serviceReqNumber = settings?.serviceReqNumber?.value;
    if (serviceReqNumber && latestSRFId != null) {
      updateSrfDetails({
        serviceReqNumber: generateNumber(
          serviceReqNumber,
          `${SRF_ID_BASE + latestSRFId}`,
          {
            branch: srfDetails?.branch,
          }
        ),
      });
    }
  }, [srfDetails?.clientId, srfDetails?.branch]);

  useEffect(() => {
    updateSrfDetails({
      serviceReqNumber: generateNumber(settings.serviceReqNumber?.value),
    });
    updateSrfDetails({
      "Certificate Number": settings["Certificate Number"]?.value,
      "ULR number": settings["ULR number"]?.value,
      "ULR ID": settings["ULR ID"]?.value,
    });
  }, [settings]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            value={
              (srfDetails?.clientId ? srfDetails?.clientId + ", " : "") +
              (srfDetails?.companyName || "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={(event, newInputValue) => {
              newInputValue = newInputValue?.label;
              let clientDetails = {};

              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue?.split(",")[0])) {
                  if (client.address) {
                    clientDetails["address"] = client.address;
                    clientDetails["branch"] = client.branch;
                  } else {
                    clientDetails["address"] = "";
                  }
                }
              });
              updateSrfDetails({
                clientId: parseInt(newInputValue?.split(",")[0]),
                companyName: newInputValue?.split(",")[1],
                ...clientDetails,
              });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfDetails.receiptDate ? srfDetails.receiptDate : new Date()
              }
              onChange={(newValue) => {
                updateSrfDetails({ receiptDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            efaultValue={srfDetails?.dcNumber || ""}
            value={srfDetails?.dcNumber || ""}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ dcNumber: e.target.value });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DC Dt./RGP Dt. *"
              value={srfDetails.dcDate ? srfDetails.dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ dcDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Entry Date *"
              value={srfDetails?.entryDate}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ entryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Committed delivery date *"
              value={
                srfDetails.committedDeliveryDate
                  ? srfDetails.committedDeliveryDate
                  : new Date()
              }
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ committedDeliveryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            variant="outlined"
            defaultValue={srfDetails?.serviceReqNumber || ""}
            value={srfDetails?.serviceReqNumber || ""}
            onChange={(e) => {
              updateSrfDetails({ serviceReqNumber: e.target.value });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={srfDetails?.address}
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ address: e.target.value });
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ poNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="PO Date *"
              value={srfDetails.poDate ? srfDetails.poDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ poDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Billing Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfDetails({
                billingId: parseInt(newInputValue.split(",")[0]),
              });
            }}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <DUCInwardDetails
        id={params?.id}
        readingRows={readingRows}
        setReadingRows={setReadingRows}
        srfInstrumentsToDelete={srfInstrumentsToDelete}
        setSrfInstrumentsToDelete={setSrfInstrumentsToDelete}
        srfDetails={srfDetails}
        updateSrfDetails={updateSrfDetails}
      />
      <hr />
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {showComponent("save") && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              if (!validateData()) return;
              if (srfDetails?.id > 0) {
                handleUpdateSubmit(
                  srfDetails,
                  readingRows,
                  srfInstrumentsToDelete,
                  settings,
                  setLoader
                );
              } else {
                handleCreateSubmit(
                  srfDetails,
                  readingRows,
                  settings,
                  setLoader
                );
              }
            }}
          >
            {params.id !=0 ? "Update" : "Save"}
          </Button>
        )}
        {false && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, ml: 2 }}
            onClick={() => {
              console.log("incomplete functionality :(");
            }}
          >
            Print
          </Button>
        )}
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
