import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import moment from "moment";
import { getUsers } from "../../utils/databaseHelper";
const axios = require("axios");

export default function CreateStandardInOut() {
  const [companyName, setCompanyName] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [purpose, setPurpose] = React.useState(null);
  const [engineerName, setEngineerName] = React.useState(null);
  const [remark, setRemark] = React.useState("");
  const [outwardDate, setOutwardDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [expectedReturnDate, setExpectedReturnDate] = React.useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const [siteInwardDate, setSiteInwardDate] = React.useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const [standards, setStandard] = React.useState("");
  const [clientsObj, setClientsObj] = React.useState([]);
  const [clientsOption, setClientsOption] = React.useState([]);
  const [checkPoints, setCheckPoints] = React.useState("");
  const [standardArr, setStandardArr] = React.useState([]);
  const [engineers, setEngineers] = React.useState([]);

  const purposeList = [
    {
      label: "Onsite",
    },
    {
      label: "self-calibration",
    },
  ];

  const getClientList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        const clients = [];
        const tClientsObj = {};
        for (let i = 0; i < res.data.length; i++) {
          clients.push({
            label: res.data[i].id + ", " + res.data[i].companyName,
          });
        }
        res.data.map((e) => (tClientsObj[e.id] = e));
        setClientsObj(tClientsObj);
        setClientsOption([...clients]);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getStandardList = () => {
    let url = BASE_URL;
    axios
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        const standardList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].standardName != null)
            standardList.push({
              id: res.data[i].id,
              label: res.data[i].standardName,
              stId: res.data[i].stId,
            });
        }
        setStandardArr(standardList);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getEngineers = () => {
    getUsers(2, (res) => {
      return res.data.map((e) => {
        return { ...e, label: e.userName };
      });
    })
      .then((engineers) => {
        setEngineers(engineers);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getClientList();
    getStandardList();
    getEngineers();
  }, []);

  const refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: companyName,
      standards: standards,
      purpose: purpose,
      outwardDate: outwardDate
        ? moment(outwardDate).format("YYYY-MM-DD")
        : null,
      expectedReturnDate: expectedReturnDate
        ? moment(expectedReturnDate).format("YYYY-MM-DD")
        : null,
      siteInwardDate: siteInwardDate
        ? moment(siteInwardDate).format("YYYY-MM-DD")
        : null,
      personName: engineerName,
      remark: remark,
      checkPoint: checkPoints,
    };
    let url = BASE_URL;
    axios
      .post(url + "standardInOut", row)
      .then((res) => {
        toast.success("standards In/Out created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Master Out
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clientsOption}
            renderInput={(params) => (
              <TextField {...params} label="Company Name" />
            )}
            onChange={(event, value) => {
              if (value != null) {
                setCompanyName(parseInt(value.label.split(",")[0]));
                setAddress(
                  clientsObj[parseInt(value.label.split(",")[0])].address
                );
              } else {
                setCompanyName(value);
                setAddress("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            options={
              standardArr.length
                ? standardArr.map((item) => {
                    return {
                      id: item.id,
                      title: item.label,
                      stId: item.stId,
                    };
                  })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stId}:{option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Standards"
                placeholder="select multiple Standards"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.id;
                  })
                  .join(",");
                setStandard(arrToStr);
              } else {
                setStandard("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={purposeList}
            renderInput={(params) => <TextField {...params} label="Purpose" />}
            onChange={(event, value) => {
              if (value != null) {
                setPurpose(value.label);
              } else {
                setPurpose("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Outward date "
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={outwardDate}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setOutwardDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Expected return date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={expectedReturnDate}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setExpectedReturnDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={engineers}
            value={engineerName}
            renderInput={(params) => (
              <TextField {...params} label="Engineer Name" />
            )}
            onChange={(_, value) => {
              setEngineerName((value && value.label) || "");
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Site Inward Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={siteInwardDate}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSiteInwardDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            value={remark}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setRemark(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Check Points"
            InputLabelProps={{ shrink: true }}
            fullWidth
            minRows={3}
            multiline
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              setCheckPoints(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
    </Paper>
  );
}
