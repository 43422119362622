import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
const axios = require("axios");

export default function CreateSupportiveInstruments(props) {
  const [srfObject, createSrfObject] = React.useState({});
  const [unitArray, setUnitArray] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);
  const [id, setId] = React.useState("")

  var [readingRows, setReadingRows] = React.useState([
    ["", "", "", ""],
  ]);

  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", ""],
  ]);

  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "range",
        headerName: "Range",
        editable: true,
      },
      {
        field: "axialUniformity",
        headerName: "Axial Uniformity",
        editable: true,
      },
      {
        field: "radialUniformity",
        headerName: "Radial Uniformity",
        editable: true,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },

      {
        field: "Action",
      },
    ],
  ];
  var refresh = () => {
    window.location.reload(false);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };
  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", ""]);
    setReadingRows([...newReadingRows]);
    let newReadingRowss = [...readingRangeValue];
    newReadingRowss.push([null, null, null, null, null, null]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
  };

  const onSubmit = (event) => {

    let row = {
      standardName: srfObject.name,
      type: "2",
      stId: id,
    };
   
    let url = BASE_URL;
    axios
      .post(url + "standards", row)
      .then((res) => {
        toast("Supportive Instruments created successfully !");
        console.log(readingRows)
        var rows = readingRows.map((row, i) => ({
          standardId:  res.data.insertId,
          rangeName:
            readingRangeValue[i][0] != null&& row[0]!=''
              ? row[0] + "#" + readingRangeValue[i][0]
              : row[0],
          axialUniformity:
            readingRangeValue[i][1] != null&& row[1]!=''
              ? row[1] + "#" + readingRangeValue[i][1]
              : row[1],
          radialUniformity:
            readingRangeValue[i][2] != null&& row[2]!=''
              ? row[2] + "#" + readingRangeValue[i][2]
              : row[2],
          stability:
            readingRangeValue[i][3] != null&& row[3]!=''
              ? row[3] + "#" + readingRangeValue[i][3]
              : row[3],
        }));
        for (let i = 0; i < rows.length; i++) {
          if(rows[i].rangeName==''){
            rows[i].rangeName = null
          } if(rows[i].stability==''){
            rows[i].stability = null
          } if(rows[i].radialUniformity==''){
            rows[i].radialUniformity = null
          } if(rows[i].axialUniformity==''){
            rows[i].axialUniformity = null
          }
        }
        console.log(rows)
        rows.map((rows) => {
          let url = BASE_URL;
          axios
            .post(url + "standardRanges", rows)
            .then((res) => {
              // console.log('submited')
              props.setLoader(false);
            })
            .catch((error) => {
              props.setLoader(false);
              toast.error("Something Went Wrong!");
            });
        });
        // submitDescriptionRows(readingRows, res.data.insertId);
        // setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  const submitDescriptionRows = (readingRows, id) => {
   
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray([
          {
            "id": -1,
            "disciplineId": null,
            "symbol": "",
            "status": 1,
        },
          ...res.data
        ])
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const UnitList = () => {
    var unitArr = [];
    for (let i = 0; i < unitArray.length; i++) {
      unitArr.push({
        label: unitArray[i].symbol,
      });
    }
    setUnitFilter(unitArr);
  };

  useEffect(() => {
    getUnitMasterArray();
  }, []);

  useEffect(() => {
    UnitList();
  }, [unitArray]);


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Supportive Instruments
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("name", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="id"
            size="small"
            fullWidth
            variant="outlined"
            value={id}
            onChange={(e) => {
              setId(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>

                        <select
                          styles={{ width: "100%" }}
                          onChange={(e) => {
                            updateRangeCellValue(
                              index,
                              cellIndex,
                              e.target.value
                            );
                          }}
                        >
                          {unitArray.map((option) => (
                            <option value={option.symbol}>{option.symbol}</option>
                          ))}
                        </select>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
