import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Autocomplete,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const MultiValueInputModal = ({
  buttonLabel,
  transformedStringLabel,
  inputStates,
  updateInputObject,
}) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([{ min: "", max: "", unit: "" }]);
  const [unitArray, setUnitArray] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
    const groups = inputStates?.[transformedStringLabel]?.split("||");
    const result = groups?.map((group) => {
      const parts = group?.split("|");
      let min, max, unit;

      if (parts.length === 1) {
        min = parts[0]?.split("#")[0];
        max = "";
        unit = parts[0]?.split("#")[1];
      } else if (parts.length === 2) {
        min = parts[0]?.split("#")[0];
        max = parts[1]?.split("#")[0];
        unit = parts[1]?.split("#")[1];
      }

      return {
        min,
        max,
        unit,
      };
    });
    inputStates?.[transformedStringLabel] && setRows(result);
  };

  const handleClose = () => {
    updateInputObject(
      transformedStringLabel,
      rows
        .map((row) => {
          const minValue = `${row.min}#${row.unit}`;
          const hasMaxValue = typeof row.max !== "undefined" && row.max !== "";
          const maxValue = hasMaxValue ? `|${row.max}#${row.unit}` : "";
          return `${minValue}${maxValue}`;
        })
        .join("||")
    );

    setOpen(false);
  };

  const handleAddRow = () => {
    const newRow = { min: "", max: "", unit: "" };
    setRows([...rows, newRow]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray([""].concat(res.data));
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < unitArray.length; i++) {
      uArr.push({
        label: unitArray[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    getUnitMasterArray();
    UnitList();
  }, [open]);

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleOpen}>
        {buttonLabel}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "50%",
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              padding: "25px",
              display: "flex",
              width: "100%",
            }}
          >
            <Typography variant="body1" color="initial">
              Update Multiple values
            </Typography>
            <Button variant="contained" onClick={handleClose}>
              X
            </Button>
          </div>
          <div
            style={{
              border: "1px solid black",
              width: "95%",
              margin: "auto",
              padding: "30px",
              marginBottom: "15px",
            }}
          >
            {rows?.map((row, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                style={{ marginBottom: "10px" }}
              >
                <Grid item>
                  <TextField
                    type="number"
                    placeholder="Min"
                    value={row.min}
                    size="small"
                    onChange={(e) =>
                      handleInputChange(index, "min", e.target.value)
                    }
                    min="0"
                    sx={{ width: "130px" }}
                  />
                </Grid>
                {(() => {
                  if (
                    transformedStringLabel !== "lc" &&
                    transformedStringLabel !== "accuracy"
                  ) {
                    return (
                      <>
                        <Grid item>
                          <span style={{ margin: "5px", fontWeight: "bold" }}>
                            TO
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            type="number"
                            placeholder="Max"
                            value={row.max}
                            size="small"
                            onChange={(e) =>
                              handleInputChange(index, "max", e.target.value)
                            }
                            min="0"
                            sx={{ width: "130px" }}
                          />
                        </Grid>
                      </>
                    );
                  }
                })()}

                <Grid item>
                  <Autocomplete
                    size="small"
                    id="combo-box-demo"
                    options={unitFilter}
                    getOptionLabel={(option) => option.label}
                    value={
                      unitFilter.find((lab) => lab.label === row.unit) || null
                    }
                    sx={{ minWidth: "170px" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Unit*" />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        handleInputChange(
                          index,
                          "unit",
                          value ? value.label : ""
                        );
                      } else {
                        handleInputChange(
                          index,
                          "unit",
                          value ? value.label : ""
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <DeleteIcon
                    onClick={() => handleDeleteRow(index)}
                    style={{
                      cursor: "pointer",
                      color: "red",
                      fontSize: "25px",
                      marginTop: "10px",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
          <Button variant="outlined" size="small" onClick={handleAddRow}>
            Add Row
          </Button>
        </Grid>
      </Modal>
    </div>
  );
};

export default MultiValueInputModal;
