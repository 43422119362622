import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { Link } from "react-router-dom";
import { ClassicTable } from "../../utils/components/Styles";
import { showComponent } from "../helper/helpers";
import { generateNumber } from "../../utils/utils";
const axios = require("axios");

const gstTypes = [
  { value: 1, label: "State GST" },
  { value: 2, label: "Central Gst" },
  { value: 3, label: "Non Gst" },
];

export default function EditQuotation(props) {
  const [productArray, setProductArray] = React.useState([]);
  const [totalAmount, setTotal] = React.useState("");
  const [clientList, setClientList] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const userType = localStorage.getItem("type");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const params = useParams();
  const [allAddress, setAllAddress] = React.useState([]);
  const [quotationObject, updateQuotationObject] = React.useState({
    subject: "Quotation for Calibration of Equipment",
    enquiryId: params.enquiryId ? params.enquiryId : null,
    enquiryDate: params.enquiryDate ? params.enquiryDate : null,
    quotationValidity: 0,
    productDetails: "",
    totalAmount: "",
    clientId: "",
    quotationNumber: "",
    referenceNumber: "",
    equipmentCollection: "",
    equipmentDelivery: "",
    date: "",
    gstType: "",
    companyName: "",
    address: "",
    termsConditions: "",
    conveyanceCharges: "",
    courierCharges: "",
    discount: null,
    discountAmount: "",
  });

  const userName = localStorage.getItem("id");
  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
        width: 10,
      },
      {
        field: "srfInstrumentId",
        headerName: "Equipment Name",
        editable: true,
      },
      // {
      //   field: "status1",
      //   headerName: "NABL Status",
      //   editable: true,
      // },
      {
        field: "discription",
        headerName: "Description",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "totalAmount",
        headerName: "Amount",
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        editable: true,
      },
    ],
  ];

  const [readingRows, setReadingRows] = React.useState([]);
  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    // setExecuting(true);
    var totalAmounts = 0;

    if (readingRows.length) {
      readingRows.map((row) => {
        totalAmounts = totalAmounts + row[4];
      });

      var rows1 = readingRows.map((row, i) => ({
        id: parseInt(i),
        srfInstrumentId: parseInt(row[1].id),
        discription: row[2],
        rate: row[3],
        quantity: row[4],
      }));
      let clientId;

      if (clientList && clientList.length > 0) {
        const client = clientList.find((e) => e.id == params.clientId);
        if (client && client.id) {
          clientId = parseInt(client.id);
        }
      }

      if (!clientId && quotationObject?.companyName?.id) {
        clientId = parseInt(quotationObject.companyName.id);
      }
      var rows = {
        productDetails: JSON.stringify(rows1),
        totalAmount: grandTotal,
        clientId: clientId || 0,
        quotationNumber: quotationObject.quotationNumber,
        referenceNumber: quotationObject.referenceNumber,
        enquiryId: quotationObject.enquiryId || null,
        enquiryDate: quotationObject.enquiryDate
          ? moment(quotationObject.enquiryDate).format("YYYY-MM-DD")
          : null,
        quotationValidity: quotationObject.quotationValidity || 0,
        equipmentCollection: quotationObject.equipmentCollection,
        address: quotationObject?.address || allAddress[0],
        equipmentDelivery: quotationObject.equipmentDelivery,
        subject: quotationObject.subject,
        date: quotationObject.date
          ? moment(quotationObject.date).format("YYYY-MM-DD")
          : null,
        termsConditions: quotationObject.termsConditions || defaultReamrk,
        conveyanceCharges: quotationObject.conveyanceCharges,
        courierCharges: quotationObject.courierCharges,
        discount: quotationObject.discount || null,
        gstType: quotationObject.gstType?.value || "",
        createdBy: userName,
      };
    }
    let url = BASE_URL;
    if (params.quotationId) {
      axios
        .patch(url + `quotation/${quotationObject.id}`, rows)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Quotation edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .post(url + "quotation", rows)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Quotation created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const invoicesData = () => {
    if (quotationObject.gstType?.value === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (quotationObject.gstType?.value === 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="GST"
              value={((18 * totalAmount) / 100).toFixed(2)}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginTop: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="SGST"
            InputLabelProps={{ shrink: true }}
            value={0}
            disabled
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="CGST"
            disabled
            value={0}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>;
    }
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", 0, 0, 0]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = async (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      if (newReadingRows[rowIndex][0] !== "") {
        await axios
          .delete(BASE_URL + `quotation/${newReadingRows[rowIndex][0]}`)
          .then((res) => {
            toast("deleted successfully");
          })
          .catch((err) => {
            console.err("error : ", err);
          });
      }
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    return axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
        return pushArray;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // api calls
  async function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null;
      let qtNumber = null;
      res.data.map((setting) => {
        if (setting.keyName === "quotation terms") {
          remark = setting.value;
        }
        if (setting.keyName === "QuotationNumber") {
          qtNumber = setting.value;
        }
      });
      // setQuotation(setting.value)
      !params.quotationId &&
        axios
          .get(BASE_URL + "quotation?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let qt = generateNumber(
              qtNumber,
              `${res.data.length > 0 ? res.data[0].id + 1 : ""}`
            );
            updateQuoObject("quotationNumber", qt);
          });
      setDefaultRemark(remark);
    });
  }

  const fetchQutationDetails = (inpt) => {
    let url = BASE_URL;
    axios
      // .get(url + `quotation/${params.quotationId}`)
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.productDetails,qt.id,qt.gstType,qt.quotationNumber,qt.referenceNumber,qt.date,qt.enquiryId,qt.enquiryDate,qt.totalAmount,client.companyName,client.contact,qt.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.conveyanceCharges,qt.courierCharges,qt.discount,qt.quotationValidity,qt.equipmentCollection,qt.equipmentDelivery,qt.clientId&_where=(qt.quotationNumber,like,${params.quotationId})`
      )
      .then((res) => {
        let quotationData = res.data[0];
        let newData = {
          ...quotationObject,
          id: quotationData?.qt_id,
          productDetails: quotationData?.qt_productDetails || "",
          totalAmount: quotationData?.qt_totalAmount || "",
          clientId: quotationData?.qt_clientId || "",
          enquiryId:
            quotationData?.qt_enquiryId && quotationData?.qt_enquiryId != ""
              ? quotationData?.qt_enquiryId
              : "",
          enquiryDate: quotationData?.qt_enquiryDate || "",
          quotationNumber: quotationData?.qt_quotationNumber || "",
          referenceNumber: quotationData?.qt_referenceNumber || "",
          quotationValidity: quotationData?.qt_quotationValidity || "",
          equipmentCollection: quotationData?.qt_equipmentCollection || "",
          equipmentDelivery: quotationData?.qt_equipmentDelivery || "",
          subject: quotationData?.qt_subject || "",
          date: quotationData?.qt_date || "",
          gstType:
            quotationData?.qt_gstType &&
            gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              ? gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              : "",
          companyName:
            inpt[0].filter((e) => e.id == quotationData?.client_id)[0] || "",
          address: quotationData?.qt_address,
          termsConditions: quotationData?.qt_termsConditions || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
          courierCharges: quotationData?.qt_courierCharges || "",
          discount: quotationData?.qt_discount || "",
        };
        quotationData && updateQuotationObject(newData);
        quotationData && setGrandTotal(quotationData?.qt_totalAmount);
        let newReadingRows = [];

        if (newData && newData.productDetails) {
          let rows1 = JSON.parse(newData.productDetails);

          const reversedRows = rows1?.map((row, id) => [
            id,
            inpt[1].filter((e) => e.id == row.srfInstrumentId)[0] || "",
            row.discription,
            row.rate,
            row.quantity,
            (row.rate || 0) * (row.quantity || 0),
          ]);

          reversedRows?.forEach((item) => {
            newReadingRows.push(item);
          });

          setReadingRows([...newReadingRows]);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateQuoObject = (key, value, q) => {
    let newQuoObject = {
      ...quotationObject,
    };

    newQuoObject[key] = value;

    updateQuotationObject({ ...newQuoObject });
  };

  const Calculate = () => {
    const discount = quotationObject.discount || 0; 
    let grandTotal = readingRows.reduce(
      (sum, val) => Number(sum) + Number(val[5]),
      0
    );
    grandTotal +=
      Number(quotationObject.conveyanceCharges || 0) +
      Number(quotationObject.courierCharges || 0);
    
    const discountAmount = (grandTotal * discount) / 100; 
   updateQuoObject(
       "discountAmount", -discountAmount)
    const totalAmountWithDiscount = grandTotal - discountAmount; 
    
    setTotal(totalAmountWithDiscount.toFixed(2)); 
    

    setGrandTotal(
      (
        totalAmountWithDiscount *
        (quotationObject.gstType?.value !== 3 &&
        quotationObject.gstType?.value !== null
          ? 1.18
          : 1)
      ).toFixed(2)
    );

  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    return axios
      .post(url + "dynamic", payload)
      .then((res) => {
        let productsArray = [];
        res.data.map((customerProduct) => {
          productsArray.push({
            id: customerProduct.id,
            label: customerProduct.instrumentName,
            rate: customerProduct.rate,
            key: customerProduct.id,
            name: customerProduct.instrumentName,
          });
        });
        setProductArray(productsArray);
        return productsArray;
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    Promise.all([
      getCustomer(),
      getProductList(),
      fetchSettings(),
      getInstrumentsList(),
    ]).then((res) => {
      if (params.quotationId) fetchQutationDetails(res);
    });
  }, []);

  useEffect(() => {
    Calculate();
  }, [
    readingRows,
    quotationObject.conveyanceCharges,
    quotationObject.courierCharges,
    quotationObject.discount,
  ]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
      Create/Update Quotation
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            disabled={params.quotationId}
            size="small"
            id="combo-box-demo"
            options={clientList}
            value={
              clientList.filter((e) => e.id == params.clientId)[0] ||
              quotationObject.companyName
            }
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={(event, value) => {
              const addressArray = value?.address
                ?.split("||")
                .map((address) => address.trim());
              setAllAddress(addressArray)
              clientList.map((client) => {
                updateQuoObject("companyName", value);
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
        {allAddress.length === 1 || params.quotationId ? (
      <TextField
        value={allAddress[0] ||  quotationObject.address || ""}
        id="outlined-basic"
        label="Address *"
        fullWidth
        variant="outlined"
        size="small"
        disabled 
      />
    ) : (
      <Autocomplete
        options={allAddress.map((option, index) => ({
          label: option,
          value: index,
        }))}
        size="small"
        getOptionLabel={(option) => option.label}
        onChange={(event, value) => {
          updateQuoObject("address", value?.label);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Address *" variant="outlined" />
        )}
      />
    )}
          {/* <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            value={
              clientList.filter((e) => e.id == params.clientId)?.[0]?.address ||
              quotationObject?.companyName?.address
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          /> */}
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Number *"
            size="small"
            value={quotationObject.quotationNumber || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Enquiry Id "
            size="small"
            fullWidth
            variant="outlined"
            value={quotationObject.enquiryId || ""}
            onChange={(e) => {
              updateQuoObject("enquiryId", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Enquiry Date "
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                quotationObject.enquiryDate
                  ? new Date(quotationObject.enquiryDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("enquiryDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Reference Number *"
            size="small"
            value={quotationObject.referenceNumber || ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("referenceNumber", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject *"
            size="small"
            value={quotationObject.subject || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("subject", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              InputLabelProps={{ shrink: true }}
              value={quotationObject.date ? new Date(quotationObject.date) : ""}
              onChange={(newValue) => {
                updateQuoObject("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Terms Conditions *"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={3}
            multiline
            value={
              quotationObject.termsConditions
                ? quotationObject.termsConditions
                : defaultReamrk
            }
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              updateQuoObject("termsConditions", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={quotationObject?.gstType}
            options={gstTypes}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onChange={(event, val) => {
              updateQuoObject("gstType", val);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Validity "
            size="small"
            value={quotationObject.quotationValidity || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("quotationValidity", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Equipment Collection "
            size="small"
            value={quotationObject.equipmentCollection || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentCollection", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Equipment Delivery *"
            size="small"
            value={quotationObject.equipmentDelivery || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentDelivery", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            disabled={readingRows[index][0] !== ""}
                            size="small"
                            id="combo-box-demo"
                            options={productArray}
                            value={readingRows[index][cellIndex]}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                              if (value != null) {
                                updateCellValue(index, cellIndex, value);
                                updateCellValue(index, 3, value.rate);
                                updateCellValue(index, 4, 0);
                                Calculate();
                              }
                            }}
                          />
                        </TableCell>
                      );
                    }
                    // if (cellIndex == 2) {
                    //   return (
                    //     <TableCell style={{ width: "200px" }}>
                    //       <Grid item xs={3}>
                    //         <Autocomplete
                    //           options={["NABL", "Non NABL"]}
                    //           getOptionLabel={(option) => option}
                    //           renderInput={(params) => (
                    //             <TextField
                    //               {...params}
                    //               label="Select NABL Status"
                    //               variant="outlined"
                    //             />
                    //           )}
                    //         />
                    //       </Grid>
                    //     </TableCell>
                    //   );
                    // }
                    if (cellIndex == 2) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              value={readingRows[index][cellIndex]}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              onChange={(e) => {
                                if (e.target.value) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    5,
                                    parseInt(readingRows[index][4]) *
                                      parseInt(e.target.value)
                                  );
                                }
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                if (e.target.value) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    5,
                                    parseInt(readingRows[index][3]) *
                                      parseInt(e.target.value)
                                  );
                                }
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              disabled
                              InputLabelProps={{ shrink: true }}
                              value={
                                readingRows[index][3] * readingRows[index][4]
                              }
                              variant="outlined"
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    {showComponent("delete") && (
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(index, row.id);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <br />
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Onsite Charges"
            size="small"
            value={quotationObject.conveyanceCharges || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("conveyanceCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Courier Charges"
            size="small"
            value={quotationObject.courierCharges || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("courierCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      {quotationObject.gstType?.value !== 3 &&
      quotationObject.gstType?.value !== null ? (
        <>
                <br />
          <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Discount (%) "
            size="small"
            value={quotationObject.discount || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("discount", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Discount Amount"
            size="small" 
            value={quotationObject.discountAmount || ""}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
        <Grid container spacing={2} style={{ marginLeft: "74.5%" }}>
          <Grid item xs={3}>
            <TextField
              disabled
              id="outlined-basic"
              label="Total Amount"
              size="small"
              value={totalAmount}
              fullWidth
              variant="outlined"
              />
          </Grid>    
        </Grid>
              </>
        
      ) : (
        ""
      )}
      {quotationObject.gstType?.value !== 3 &&
      quotationObject.gstType?.value !== null
        ? invoicesData()
        : ""}
      <br />

      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            disabled
            id="outlined-basic"
            label="Grand Total Amount"
            size="small"
            value={grandTotal}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            // onClick={() => {
            //   quotationObject.gstType?.value != null
            //     ? handleSubmit()
            //     : toast.error("Please select GST Type!");
            // }}
            onClick={() => {
              if (
                quotationObject.gstType?.value !== null &&
                quotationObject.companyName &&
                quotationObject.date &&
                readingRows.length > 0
              ) {
                handleSubmit();
              } else {
                if (!quotationObject.gstType?.value) {
                  toast.error("Please select GST Type!");
                }
                if (!quotationObject.companyName) {
                  toast.error("Please select Company Name!");
                }
                if (!quotationObject.date) {
                  toast.error("Please enter Date!");
                }
                if (!readingRows.length) {
                  toast.error("Please enter Add Product and Quantity!");
                }
              }
            }}
          >
            {params?.clientId ? "Update" : "Save"}
          </Button>
        ) : (
          console.log("")
        )}
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
