import axios from "axios";
import { BASE_URL } from "../global";

export const getUsers = async (type, process) => {
  let query =
    BASE_URL +
    `users?_where=(status,eq,1)${type ? `~and(type,eq,${type})` : ""}`;
  return axios.get(query).then((res) => {
    if (process) return process(res);
    return res;
  });
};
